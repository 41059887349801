        import styled from 'styled-components';

        const ServicesHeroSectionStyle = styled.section`
        box-sizing: border-box;
        margin-top: 130px;
        width: 100%;

        @media only screen and (max-width: 576px){
        margin-top: 110px;
        }

        .services__desc{
        h2{
        color: #828282;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.47px;
        text-transform: uppercase;
        @media only screen and (max-width: 768px){
        font-size: 12px;
        }
        }
        }
        .services__content{
        margin-top: 20px;
        }
        .services__content__left{
        margin-bottom: 0;
        }
        .services__content__left{
        h2{
        color: #1C6A69;
        font-family: Noto Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;

        @media only screen and (max-width: 992px){
        font-size: 24px;
        }

        @media only screen and (max-width: 768px){
        font-size: 18px;
        }

        @media only screen and (max-width: 576px){
        font-size: 16px;
        margin-bottom: 16px;
        }
        }
        }
        .services__content__right{
        margin-bottom: 0;
        p{
        color: #333;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        margin-bottom: 30px;
        &:last-child{
        margin-bottom: 0;
        }
        span{
        font-family: Noto Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        @media only screen and (max-width: 992px){
        font-size: 16px;
        }

        @media only screen and (max-width: 768px){
        font-size: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        }

        @media only screen and (max-width: 768px){
        font-size: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        }
        `;

        export default ServicesHeroSectionStyle;

import React from 'react';
import ContactStyle from './ContactStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import location from '../../../assets/Images/location.svg';
import phone from '../../../assets/Images/phone.svg';
import mail from '../../../assets/Images/mail.svg';
import ContactForm from '../../molecules/ContactForm/ContactForm';

const Contact = () => {

  return (
    <ContactStyle className={`container Contact`}>
      <div className={`container-full contact__desc`}>
        <HeaderText value='Are you ready to start? There is one simple step you can take right now – write to us now by filling the Form below:' />
      </div>
      <div className={`container-full contact__body`}>
        <div className={`div-md-7 contact__body__form`}>
          <ContactForm />
        </div>
        <div className={`div-md-5 contact__body__info`}>
          <div className={`container-full contact__body__info__desc`}>
            <HeaderText value='Do you have any questions?' />
          </div>
          <div className={`container-full contact__body__info__content`}>
            <div className={`container-full contact__body__info__content__row`}>
              <div className={`contact__body__info__content__row__img`}>
                <Img image={location} />
              </div>
              <div className={`contact__body__info__content__row__text`}>
                <HeaderText value='Our Office' />
                <Text value='Nyesi Ibrahim Tella, Lagos state' />
              </div>
            </div>
            <div className={`container-full contact__body__info__content__row`}>
              <div className={`contact__body__info__content__row__img`}>
                <Img image={phone} />
              </div>
              <div className={`contact__body__info__content__row__text`}>
                <HeaderText value='Talk to our Experts' />
                <Text value='+234 817 272 8244, +234 703 555 1282' />
              </div>
            </div>
            <div className={`container-full contact__body__info__content__row`}>
              <div className={`contact__body__info__content__row__img`}>
                <Img image={mail} />
              </div>
              <div className={`contact__body__info__content__row__text`}>
                <HeaderText value='Send us a mail' />
                <p> Our friendly team is here to help you. Send a mail to <span> info@globedgecapital.com </span> </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContactStyle>
  );
}

export default Contact;
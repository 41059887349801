import React, { useEffect } from 'react';
import AboutTemplateStyles from './AboutTemplateStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Navbar from '../../organisms/Navbar/Navbar';
import Footer from '../../organisms/Footer/Footer';
import AboutHeroSection from '../../organisms/AboutHeroSection/AboutHeroSection';
import AboutCarousel from '../../organisms/AboutCarousel/AboutCarousel';
import AboutGoal from '../../organisms/AboutGoal/AboutGoal';
import VisionMission from '../../organisms/VisionMission/VisionMission';
import CoreValue from '../../organisms/CoreValue/CoreValue';
import OurClients from '../../organisms/OurClients/OurClients';
import Testimonials from '../../organisms/Testimonials/Testimonials';
import Contact from '../../organisms/Contact/Contact';

const AboutTemplate = () => {

  return (
    <>
      <AboutTemplateStyles>
        <div className={`container-full`}>
          <div className={`container-full nav`}>
            <Navbar />
          </div>
          <AboutHeroSection />
          <AboutCarousel />
          <AboutGoal />   
          <VisionMission />
          <CoreValue />
          <OurClients />
          <section className={`container-full testimonials`}>
            <Testimonials />
          </section>
          <section className={`container-full contact`}>
            <Contact />
          </section>
          <Footer />
        </div>
      </AboutTemplateStyles>
    </>
  );
};

export default AboutTemplate;

const Colors = {
  success: '#08801C',
  danger: '#F33535',
  brand: '#00425F',
  black: '#353F50',
  neutral: '#353F50',
  activeBlue: '#0275D8',
  white: '#FFFFFF'
};

export default Colors;
        import styled from "styled-components";

        const FaqStyle = styled.div`
        margin-top: 80px;
        margin-bottom: 80px;
        width: 100%;

        @media only screen and (max-width: 576px){
        margin-top: 0px;
        margin-bottom: 30px;
        }

        .faq{
        flex-direction: column;

        }

        .faq-item, .faq__desc{
        margin-bottom: 22px;
        @media only screen and (max-width: 992px){
        margin-bottom: 20px;
        }

        @media only screen and (max-width: 768px){
        margin-bottom: 18px;
        }

        @media only screen and (max-width: 576px){
        margin-bottom: 16px;
        }
        }

        .faq-header_text{
        color: #333333;
        font-family: Noto Sans;
        font-size: 32px;
        font-weight: 600;
        line-height: 43.58px;
        text-align: left;
        width: 100%;
        @media only screen and (max-width: 992px){
        font-size: 22px;
        }

        @media only screen and (max-width: 768px){
        font-size: 18px;
        }

        @media only screen and (max-width: 576px){
        font-size: 14px;
        }
        }

        .faq__desc{
        color: #000000;    
        font-family: Noto Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 43.58px;
        text-align: left;
        @media only screen and (max-width: 992px){
        font-size: 16px;
        }

        @media only screen and (max-width: 768px){
        font-size: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 12px;
        line-height: 19.07px;
        }
        }


        .faq-title_container{
        align-items: center;
        padding: 20px 24px 20px 24px;
        border-radius: 8px;
        border: 1px solid #77777766;
        cursor: pointer;
        @media only screen and (max-width: 576px){
        padding: 8px 24px 8px 24px;
        }

        @media only screen and (max-width: 340px){
        padding: 8px 12px 8px 11px;
        }

        .faq-title{
        font-family: Noto Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-align: left;

        @media only screen and (max-width: 992px){
        font-size: 16px;
        }

        @media only screen and (max-width: 768px){
        font-size: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 12px;        
        }

        }
        .faq_icon{
        width : 18.67px;
        height: 18.67px;

        @media only screen and (max-width: 992px){
        width: 15.67px;
        height: 15.67px;
        }

        @media only screen and (max-width: 768px){
        width: 13.67px;
        height: 13.67px;
        }

        @media only screen and (max-width: 576px){
        width: 11.67px;
        height: 11.67px;
        }
        }
        }

        .faq-content{
        font-family: Noto Sans;
        font-size: 17px;
        font-weight: 400;
        text-align: left;
        margin-top: 20px; 

        @media only screen and (max-width: 992px){
        font-size: 15px;  
        margin-top: 15px;   
        }

        @media only screen and (max-width: 768px){
        font-size: 13px;
        margin-top: 13px;
        }

        @media only screen and (max-width: 576px){
        font-size: 11px; 
        margin-top: 11px;
        }

        }

        .faq-footer{
        margin-top: 16px;
        }

        .faq_button{
        cursor: pointer;
        font-family: Noto Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #1C6A69;
        border: 1px solid #1C6A69;
        background-color: white;
        border-radius: 8px;
        width: 146px;
        height: 48px;
        @media only screen and (max-width: 768px){
        font-size: 14px;
        line-height: 21px;
        }
        }



        `

        export default FaqStyle
import React from 'react';
import MobileNavbarStyle from './MobileNavbarStyles';
import { Link } from 'react-router-dom';



const MobileNavbar = () => {

    return (
        <MobileNavbarStyle>
            <nav className="menu">
                <ul className="menu-items">
                    <li className="list-item">
                        <Link to="/about">
                            <p> About Us  </p>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/strategy">
                            <p> Strategy  </p>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/operations">
                            <p> Operations  </p>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/technology">
                            <p> Technology  </p>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/learning-and-development">
                            <p> Learning &amp; Development  </p>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/services">
                            <p> People Services  </p>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/contact">
                            <p> Contact  </p>
                        </Link>
                    </li>
                </ul>
            </nav>
        </MobileNavbarStyle>
    );
}

export default MobileNavbar;
import React, { useState } from 'react'
import FaqStyle from './FaqStyle'
import Heading from '../../atoms/HeaderText/Text'
import Text from '../../atoms/Text/Text'
import Img from '../../atoms/Image/Img'
import faqopenImg from "../../../assets/Images/faqOpen.svg";
import faqCloseImg from "../../../assets/Images/dash-lg.svg"
import Button from '../../atoms/Button/Button'

const Faq = () => {

    const [openIndex, setOpenIndex] = useState(null);

    const toggleQuestion = (index: any) => {
        setOpenIndex(openIndex === index ? null : index);
    };


    return (
        <FaqStyle>
            <section className={'container faq'}>
                <Heading className={'faq-header_text'} value='Frequently asked questions' />
                <Text className={'faq__desc'} value='Learn more about our pricing, mentoring, and so on.' />
                <div className='faq-item__container'>
                    <div className={'faq-item'}>
                        <div
                            className={' container faq-title_container'}
                            onClick={() => toggleQuestion(0)}
                        >
                            <p className='faq-title'>Is there a timing for the consulting session?</p>
                            {openIndex === 0 ? <Img className='faq_icon' image={faqCloseImg} /> : <Img className='faq_icon' image={faqopenImg} />}
                        </div>
                        {openIndex === 0 && (
                            <Text className=' container faq-content' value='Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum accusantium exercitationem dolores nemo praesentium eos dignissimos officia veniam nisi? Doloremque.' />
                        )}
                    </div>

                    <div className='faq-item'>
                        <div
                            className='container faq-title_container'
                            onClick={() => toggleQuestion(1)}
                        >
                            <p className='faq-title'>Is there any booking fees?</p>
                            {openIndex === 1 ? <Img className='faq_icon' image={faqCloseImg} /> : <Img className='faq_icon' image={faqopenImg} />}
                        </div>
                        {openIndex === 1 && (
                            <Text className=' container faq-content' value='Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum accusantium exercitationem dolores nemo praesentium eos dignissimos officia veniam nisi? Doloremque.' />
                        )}
                    </div>
                    <div className='faq-item'>
                        <div
                            className=' container faq-title_container'
                            onClick={() => toggleQuestion(2)}
                        >
                            <p className='faq-title'>Can I rescheduled booking appointment?</p>
                            {openIndex === 2 ? <Img className='faq_icon' image={faqCloseImg} /> : <Img className='faq_icon' image={faqopenImg} />}
                        </div>
                        {openIndex === 2 && (
                            <Text className=' container faq-content' value='Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum accusantium exercitationem dolores nemo praesentium eos dignissimos officia veniam nisi? Doloremque.' />
                        )}
                    </div>
                    <div className='faq-item'>
                        <div
                            className='container faq-title_container'
                            onClick={() => toggleQuestion(3)}
                        >
                            <p className='faq-title'>What are the benefit of consultations?</p>
                            {openIndex === 3 ? <Img className='faq_icon' image={faqCloseImg} /> : <Img className='faq_icon' image={faqopenImg} />}
                        </div>
                        {openIndex === 3 && (
                            <Text className=' container faq-content' value='Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum accusantium exercitationem dolores nemo praesentium eos dignissimos officia veniam nisi? Doloremque.' />
                        )}
                    </div>
                </div>

                <div className='faq-footer'>
                    <Heading className={'faq-header_text'} value='Still have a question?' />
                    <Text className={'faq__desc'} value='Please feel free to contact Us with any enquiries you may have.' />

                    <Button className='faq_button' value={"Contact Us"} />
                </div>
            </section>
        </FaqStyle>
    )
}

export default Faq
      import styled from 'styled-components';

      const ServicesTemplateStyle = styled.div`
      .nav{
      background: white;
      position: fixed;
      z-index: 5000;
      }

      .services__quote{
      margin-top: 30px;
      }

      .services__quote__desc{
      h2{
      color: #1C6A69;
      font-family: Noto Sans;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      @media only screen and (max-width: 992px){
      font-size: 22px;
      }

      @media only screen and (max-width: 768px){
      font-size: 18px;
      }

      @media only screen and (max-width: 576px){
      font-size: 14px;
      }
      }
      }

      .services__quote__text{
      margin-top: 24px;
      p{
      color: #4F4F4F;
      font-family: Noto Sans;
      font-size: 24px;
      font-style: italic;
      font-weight: 400;
      line-height: 150%; /* 36px */
      text-align: center;
      width: 90%;
      @media only screen and (max-width: 992px){
      font-size: 18px;
      }

      @media only screen and (max-width: 768px){
      font-size: 16px;
      }

      @media only screen and (max-width: 576px){
      font-size: 14px;
      }
      }
      }

      .services__quote__info{
      margin-top: 24px;
      p{
      color: #333;
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 175%; /* 28px */
      margin-bottom: 30px;
      &:last-child{
      margin-bottom: 0;
      }

      @media only screen and (max-width: 992px){
      font-size: 14px;
      }
      @media only screen and (max-width: 768px){
      font-size: 12px;
      }      
      }
      }

      .contact{
      background: white;
      box-sizing: border-box;
      padding: 120px 0 41px 0;
      }
      `;

      export default ServicesTemplateStyle;

import React, { useEffect } from 'react';
import TechnologyTemplateStyles from './TechnologyTemplateStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Navbar from '../../organisms/Navbar/Navbar';
import Footer from '../../organisms/Footer/Footer';
import Contact from '../../organisms/Contact/Contact';
import OperationsHeroSection from '../../organisms/OperationsHeroSection/OperationsHeroSection';
import OperationsCarousel from '../../organisms/OperationsCarousel/OperationsCarousel';
import Benefits from '../../organisms/Benefits/Benefits';
import BusinessOperations from '../../organisms/BusinessOperations/BusinessOperations';
import TechnologyCarousel from '../../organisms/TechnologyCarousel/TechnologyCarousel';
import TechnologyContent from '../../organisms/TechnologyContent/TechnologyContent';

const TechnologyTemplate = () => {

  return (
    <>
      <TechnologyTemplateStyles>
        <div className={`container-full`}>
          <div className={`container-full nav`}>
            <Navbar />
          </div>
          <div className={`container technology-hero`}>
            <div className={`container-full center technology-hero__desc`}>
              <HeaderText value='BUSINESS TECHNOLOGY' />
              <Text value='“At least 40% of all businesses will die in the next 10years, if they don’t figure out how to change their company to accommodate new technologies” – John Chambers, CISCO.' />
            </div>
          </div>
          <TechnologyCarousel />
          <TechnologyContent />
          <section className='container contact'>
            <Contact />   
          </section>
          <Footer />
        </div>
      </TechnologyTemplateStyles>
    </>
  );
};

export default TechnologyTemplate;

import styled from 'styled-components';

const HeroSectionStyle = styled.section`
    background: #1C6A69;
    margin-top: 96px;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 1200px){
        margin-top: 85px;
    }

    @media only screen and (max-width: 992px){
        margin-top: 67px;
    }

    @media only screen and (max-width: 576px){
        margin-top: 50px;
    }

    .hero{
        box-sizing: border-box;
        padding: 160px 0 177px 0;
        @media only screen and (max-width: 992px){
            &{
                padding: 130px 15px 147px 15px;
            }
        }
        @media only screen and (max-width: 767px){
            &{
                padding: 100px 15px 117px 15px;
            }
        }
        @media only screen and (max-width: 576px){
            &{
                padding: 64px 15px 50px 15px;
            }
        }
    }
    .hero__box{
        -ms-flex: 0 0 69%;
        -webkit-flex: 0 0 69%;
        flex: 0 0 69%;
        max-width: 69%;
        @media only screen and (max-width: 992px){
            -ms-flex: 0 0 85%;
            -webkit-flex: 0 0 85%;
            flex: 0 0 85%;
            max-width: 85%;
        }
        @media only screen and (max-width: 767px){
            -ms-flex: 0 0 100%;
            -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        margin-bottom: 0;
        h2{
            color: white;
            font-family: Noto Sans;
            font-size: 3em;
            font-style: normal;
            font-weight: 800;
            line-height: 100%;
            letter-spacing: -0.24px;
            margin-bottom: 24px;
            span{
                color: #3CE05A;
                font-family: Noto Sans;
            }
            @media only screen and (max-width: 992px){
                font-size: 2.8em;
                line-height: 50px;
                margin-bottom: 20px;
            }
            @media only screen and (max-width: 767px){
                font-size: 2.5em;
                line-height: 50px;
                margin-bottom: 20px;
            }
            @media only screen and (max-width: 576px){
                font-size: 20px;
                line-height: 27px;
                margin-bottom: 16px;
            }
        }
        p{
            color: #FFF;
            font-family: Noto Sans;
            font-size: 1.250em;
            font-style: normal;
            font-weight: 400;
            line-height: 175%; 
            margin-bottom: 41px;
            @media only screen and (max-width: 992px){
                font-size: 1.2em;
                line-height: 170%;
                margin-bottom: 38px;
            }
            @media only screen and (max-width: 767px){
                font-size: 1.1em;
                line-height: 150%;
                margin-bottom: 35px;
            }
            @media only screen and (max-width: 767px){
                font-size: 12px;
                line-height: 21px;
                margin-bottom: 48px;
            }
        }
        .button{
            background: #FFF;
            border: 1px solid #FFF;
            border-radius: 16px;
            color: #1C6A69;
            font-family: Noto Sans;
            font-size: 1em;
            font-style: normal;
            font-weight: 600;
            height: 72px;
            margin-bottom: 0;
            width: 295px;
            @media only screen and (max-width: 767px){
                height: 60px;
                width: 230px;
            }
        }
    }
    .vector{
        position: absolute;
        right: 0;
        top: 0;
    }
    .--subtract{
        margin-right: 191px;
        margin-top: 77px;
        @media only screen and (max-width: 992px){
            margin-right: 161px;
            width: 30px;
        }
        @media only screen and (max-width: 768px){
            margin-right: 131px;
            width: 27px;
        }
        @media only screen and (max-width: 576px){
            margin-right: 24px;
            margin-top: 213px;
            width: 8px;
        }
    }
    .--intersect{
        margin-right: 186.07px;
        margin-top: 209px;
        @media only screen and (max-width: 992px){
            margin-right: 156px;
            width: 33px;
        }
        @media only screen and (max-width: 768px){
            margin-right: 126px;
            width: 39px;
        }
        @media only screen and (max-width: 576px){
            margin-right: 24px;
            margin-top: 249px;
            width: 13px;
        }
    }
    .--moon{
        margin-right: 247.34px;
        margin-top: 375px;
        @media only screen and (max-width: 992px){
            margin-right: 217px;
            width: 90px;
        }
        @media only screen and (max-width: 768px){
            margin-right: 187px;
            width: 87px;
        }
        @media only screen and (max-width: 576px){
            margin-right: 27.02px;
            margin-top: 303px;
            width: 30px;
        }
    }
    .--full-moon{
        margin-right: 461px;
        margin-top: 560px;
        @media only screen and (max-width: 992px){
            margin-right: 431px;
            margin-top: 500px;
            width: 120px;
        }
        @media only screen and (max-width: 768px){
            margin-right: 401px;
            margin-top: 530px;
            width: 100px;
        }
        @media only screen and (max-width: 768px){
            margin-right: 69px;
            margin-top: 330px;
            width: 40px;
        }
    }
`;

export default HeroSectionStyle;

import React, { useState, useEffect } from 'react';
import HeroSectionStyle from './HeroSectionStyles';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Img from '../../atoms/Image/Img';
import moon from '../../../assets/Images/moon.svg';
import subtract from '../../../assets/Images/Subtract.svg';
import intersect from '../../../assets/Images/Intersect.svg';
import fullmoon from '../../../assets/Images/full-moon.svg';



const HeroSection = () => {

    return (
        <HeroSectionStyle className={`container-full`}>
           <div className={`container hero`}>
             <div className={`hero__box`}>
                <h2> Experience the <span>edge</span> in global business consulting </h2>
                <Text value='Unlock your business potential with Globedge Capital. You will be able to reach new heights and sculpt change with the aid of our agile problem-solving and strategic planning methods.' />
                <Button value={`Check our training calendar`} className="button" />
             </div>
           </div>
           <Img image={moon} className="vector --moon" />
           <Img image={subtract} className="vector --subtract" />
           <Img image={intersect} className="vector --intersect" />
           <Img image={fullmoon} className="vector --full-moon" />
        </HeroSectionStyle>
    );
}

export default HeroSection;
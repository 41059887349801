import React from 'react';
import WhoWeAreStyle from './WhoWeAreStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import who from '../../../assets/Images/who-we-are.svg';
import whoMobile from '../../../assets/Images/who-we-are-mobile.svg';
import arrow from '../../../assets/Images/arrow.svg';
import { NavLink } from 'react-router-dom';

const WhoWeAre = () => {

  return (
    <WhoWeAreStyle className={`container-full`}>
      <div className={`container who`}>
        <HeaderText value='Who We are?' className='who__desc' />
        <div className={`div-md-6 who__item`}>
          <Img image={who} className="img --desktop" />
          <Img image={whoMobile} className="img --mobile" />
        </div>
        <div className={`div-md-6 who__item`}>
          <HeaderText value='Who we are?' />
          <Text className="--first" value='We are a team of consultants having robust and combined experience of over 20years in consulting (building corporate brands) and transforming businesses across Africa, starting from Nigeria.'/>
          <Text value='Our focus remains identifying internal gaps within organizations and proffering leading solutions through practical innovations in business process improvement, strategy and operations remodeling, business technology, resource management, and workforce learning & development to keep organizations constantly transformed & informed of industry knowledge, standards and trends in the highly competitive local & global landscape and ever-evolving people-process-technology business world.' />
          <NavLink to="/about">
            <p>Read more</p>
            <Img image={arrow} />
          </NavLink>
        </div>
      </div>
    </WhoWeAreStyle>
  );
}

export default WhoWeAre;
import React from 'react';
import TechnologyCarouselStyle from './TechnologyCarouselStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import carousel10 from '../../../assets/Images/carousel10.svg';
import carousel11 from '../../../assets/Images/carousel11.svg';
import carousel12 from '../../../assets/Images/carousel12.svg';

const TechnologyCarousel = () => {

  return (
    <TechnologyCarouselStyle className={`container technology-carousel`}>
        <Carousel
          showArrows={false}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={2100}
        >
          <div className={`container-full technology-carousel__box`}>
            <Img image={carousel10} className="img" />
          </div>
          <div className={`container-full technology-carousel__box`}>
            <Img image={carousel11} className="img" />
          </div>
          <div className={`container-full technology-carousel__box`}>
            <Img image={carousel12} className="img" />
          </div>
        </Carousel>
    </TechnologyCarouselStyle>
  );
}

export default TechnologyCarousel;
import React from 'react';
import ServicesCarouselStyle from './ServicesCarouselStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import carousel16 from '../../../assets/Images/carousel16.svg';
import carousel17 from '../../../assets/Images/carousel17.svg';
import carousel18 from '../../../assets/Images/carousel18.svg';

const ServicesCarousel = () => {

  return (
    <ServicesCarouselStyle className={`container about-carousel`}>
        <Carousel
          showArrows={false}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={2100}
        >
          <div className={`container-full about-carousel__box`}>
            <Img image={carousel16} className="img" />
          </div>
          <div className={`container-full about-carousel__box`}>
            <Img image={carousel17} className="img" />
          </div>
          <div className={`container-full about-carousel__box`}>
            <Img image={carousel18} className="img" />
          </div>
        </Carousel>
    </ServicesCarouselStyle>
  );
}

export default ServicesCarousel;
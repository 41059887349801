import React from 'react';
import OperationsCarouselStyle from './OperationsCarouselStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import carousel7 from '../../../assets/Images/carousel7.svg';
import carousel8 from '../../../assets/Images/carousel8.svg';
import carousel9 from '../../../assets/Images/carousel9.svg';

const OperationsCarousel = () => {

  return (
    <OperationsCarouselStyle className={`container operations-carousel`}>
        <Carousel
          showArrows={false}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={2100}
        >
          <div className={`container-full operations-carousel__box`}>
            <Img image={carousel7} className="img" />
          </div>
          <div className={`container-full operations-carousel__box`}>
            <Img image={carousel8} className="img" />
          </div>
          <div className={`container-full operations-carousel__box`}>
            <Img image={carousel9} className="img" />
          </div>
        </Carousel>
    </OperationsCarouselStyle>
  );
}

export default OperationsCarousel;
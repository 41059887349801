        import styled from 'styled-components';

        const BenefitsStyle = styled.section`
        margin-top: 90px;
        width: 100%;
        @media only screen and (max-width: 576px){
        margin-top: 45px;
        }

        .ld-benefits__desc{
        p{
        color: #333;
        font-family: Noto Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        @media only screen and (max-width: 992px){
        font-size: 24px;
        }

        @media only screen and (max-width: 768px){
        font-size: 18px;
        }

        @media only screen and (max-width: 576px){
        font-size: 16px;
        }
        }
        }
        .ld-benefits__content{
        margin-top: 40px;
        }
        .ld-benefits__content__row{
        margin-bottom: 24px;
        
        @media only screen and (max-width: 768px){
         margin-bottom: 0px;
        }
        &:last-child{
        margin-bottom: 0;
        }
        }
        .ld-benefits__content__row__item{
        margin-bottom: 0;
        img{
        width: 80px;
        height: 80px;
        @media only screen and (max-width: 992px){
        width: 60px;
        height: 60px;
        }
        @media only screen and (max-width: 576px){
        width: 40px;
        height: 40px;
        }
        }
        p{
        color: var(--gray-1, #333);
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; /* 28px */
        margin-top: 29px;
        text-align: center;
        width: 100%;
        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        }
        @media only screen and (max-width: 768px){
        font-size: 14px;
        margin-top: 16px;
        margin-bottom: 24px;
        }
        @media only screen and (max-width: 576px){
        font-size: 12px;
        margin-top: 14px;
        margin-bottom: 24px;
        }
        }
        }

        `;

        export default BenefitsStyle;

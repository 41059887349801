      import styled from 'styled-components';

      const AboutTemplateStyle = styled.div`
      .nav{
      background: white;
      position: fixed;
      z-index: 5000;
      }

      .testimonials{
      background: white;
      box-sizing: border-box;
      padding: 30px 0 30px 0;
      }
      .contact{
      background: white;
      box-sizing: border-box;
      padding: 105px 0 41px 0;
      }
      .quotes{
      margin-top: 15px;
      }
      .quotes__desc{
      h2{
      color: #333;
      text-align: center;
      font-family: Noto Sans;
      font-size: 24px;
      font-style: italic;
      font-weight: 400;
      line-height: 150%;
      margin: auto;
      text-align: center;
      width: 80%;
      @media only screen and (max-width: 992px){
      font-size: 18px;
      }

      @media only screen and (max-width: 768px){
      font-size: 16px;
      }

      @media only screen and (max-width: 576px){
      font-size: 14px;
      }
      }
      }
      .quotes__text{
      margin-top: 31px;
      p{
      color: #333;
      font-family: Noto Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 175%;
      @media only screen and (max-width: 992px){
      font-size: 16px;
      }

      @media only screen and (max-width: 768px){
      font-size: 14px;
      }

      @media only screen and (max-width: 576px){
      font-size: 12px;
      }
      }
      }
      `;

      export default AboutTemplateStyle;

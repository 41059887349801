        import styled from "styled-components";

        const DetailsModalStyle = styled.div`
        animation: show-bg 0.5s linear forwards;
        box-sizing:border-box;
        display:flex;
        height:100%;
        left:0;
        position:absolute;
        position:fixed;
        top: 0;
        width:100%;
        z-index:5000;
        @keyframes show-bg{
        0%{
        background:rgba(0, 0, 0, 0)
        }
        100%{
        background: rgba(0, 0, 0, 0.40);
        }
        }
        .modal-container{
        box-sizing:border-box;
        height:100vh;

        }
        .modal-container__box{
        -ms-flex: 0 0 835px;
        -webkit-flex: 0 0 835px;
        flex: 0 0 835px;
        max-width: 835px;
        background:#FFFFFF;
        border-radius:16px;
        box-sizing:border-box;
        padding: 40px 40px 60px 40px;
        @media only screen and (max-width: 992px){
        padding: 30px 30px 40px 30px;
        } 

        @media only screen and (max-width: 768px){
        padding: 20px 20px 30px 20px;
        } 

        @media only screen and (max-width: 576px){
        padding: 15px 15px 20px 15px;
        }

        @media only screen and (max-width: 992px){
        -ms-flex: 0 0 635px;
        -webkit-flex: 0 0 635px;
        flex: 0 0 635px;
        max-width: 635px;
        } 

        @media only screen and (max-width: 768px){
        -ms-flex: 0 0 500px;
        -webkit-flex: 0 0 500px;
        flex: 0 0 500px;
        max-width: 500px;
        } 

        @media only screen and (max-width: 576px){
        -ms-flex: 0 0 270px;
        -webkit-flex: 0 0 270px;
        flex: 0 0 270px;
        max-width: 270px;
        }
        }
        .modal-container__box__bg{
        border-radius: 8px;
        height: 240px;
        background-size: cover;
        background-repeat: no-repeat;
        @media only screen and (max-width: 992px){
        height: 200px;
        } 

        @media only screen and (max-width: 768px){
        height: 160px;
        } 

        @media only screen and (max-width: 576px){
        height: 140px;
        }
        }
        .modal-container__box__content{
        margin-top: 40px;
        @media only screen and (max-width: 992px){
        margin-top: 30px;
        } 

        @media only screen and (max-width: 768px){
        margin-top: 25px;
        } 

        @media only screen and (max-width: 576px){
        margin-top: 20px;
        }       
        h2{
        color: #1C6A69;
        font-family: Noto Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 16px;
        @media only screen and (max-width: 992px){
        font-size: 22px;
        } 

        @media only screen and (max-width: 768px){
        font-size: 18px;
        } 

        @media only screen and (max-width: 576px){
        font-size: 16px;
        }

        }
        p{
        color: #333;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        } 

        @media only screen and (max-width: 768px){
        font-size: 14px;
        } 

        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        }
        .modal-container__close{
        align-self: top;
        margin-top: 0px;
        margin-top: -32px;
        @media only screen and (max-width: 768px){
        margin-top: -10px;
        margin-left: -25px;
        } 
        img{
        cursor: pointer;
        @media only screen and (max-width: 768px){
        width: 35px;
        height: 35px;
        } 
        }
        }




        `
        export default DetailsModalStyle;
import React from 'react';
import BenefitsStyle from './BenefitsStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import reduce from '../../../assets/Images/Reduce.svg';
import product from '../../../assets/Images/Product.svg';
import happy from '../../../assets/Images/happy.svg';
import productivity from '../../../assets/Images/Productivity.svg';
import money from '../../../assets/Images/Increase Money.svg';
import achievement from '../../../assets/Images/Achievement.svg';

const Benefits = () => {

  return (
    <BenefitsStyle className={`container-full benefits`}>
      <div className={`container center benefits__desc`}>
        <Text value='The central benefits of effective operations management we will bring to your business' />
      </div>
      <div className={`container benefits__content`}>
        <div className={`container-full benefits__content__row`}>
          <div className={`div-md-3 benefits__content__row__item`}>
            <div className={`container-full center`}>
              <Img image={reduce} />
              <Text value='Waste Reduction' />
            </div>
          </div>
          <div className={`div-md-3 benefits__content__row__item`}>
            <div className={`container-full center`}>
              <Img image={product} />
              <Text value='Enhanced Product/Service Quality' />
            </div>
          </div>
          <div className={`div-md-3 benefits__content__row__item`}>
            <div className={`container-full center`}>
              <Img image={happy} />
              <Text value='High Customer Satisfaction' />
            </div>
          </div>
        </div>
        <div className={`container-full benefits__content__row`}>
          <div className={`div-md-3 benefits__content__row__item`}>
            <div className={`container-full center`}>
              <Img image={productivity} />
              <Text value='Enhanced Employee Productivity' />
            </div>
          </div>
          <div className={`div-md-3 benefits__content__row__item`}>
            <div className={`container-full center`}>
              <Img image={money} />
              <Text value='Increase in Revenue' />
            </div>
          </div>
          <div className={`div-md-3 benefits__content__row__item`}>
            <div className={`container-full center`}>
              <Img image={achievement} />
              <Text value='Achievement of Company Objectives' />
            </div>
          </div>
        </div>
      </div>
    </BenefitsStyle>
  );
}

export default Benefits;
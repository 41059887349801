        import styled from 'styled-components';

        const StrategiesStyle = styled.section`
        box-sizing: border-box;
        padding: 40px 0 38px 0;
        width: 100%;

        .strategies__desc{
        h2{
        color: #333;
        text-align: center;
        font-family: Noto Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
        @media only screen and (max-width: 992px){
        &{
        font-size: 20px;
        }
        }
        @media only screen and (max-width: 768px){
        &{
        font-size: 18px;
        }
        }
        @media only screen and (max-width: 576px){
        &{
        font-size: 16px;
        padding-right: 15px;
        padding-left: 15px;
        }
        }
        }
        }
        .strategies__content{
        margin-top: 40px;
        display: flex;
        @media only screen and (max-width: 576px){
        display: none;
        }

        }

        .strategy-item__carousel{
        display: none;
        @media only screen and (max-width: 576px){
        display: block;
        margin-top: 20px;
        }
        }


        .carousel .control-dots .dot {
        box-shadow: none !important;
        background: #F2F2F2 !important;
        outline: 0;
        opacity: 1;
        }

        .carousel .control-dots .dot.selected{
        background: #3CE05A !important;
        }




        .strategies__content__row{
        margin-bottom: 20px;
        &:last-child{
        margin-bottom: 0;
        }
        }


        .strategies__content__row__item{
        background: #FFF;
        border-radius: 16px;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);
        box-sizing: border-box;
        margin-bottom: 0;
        padding: 24px 24px 32px 24px;
        -ms-flex: 0 0 32.3%;
        -webkit-flex: 0 0 32.3%;
        flex: 0 0 32.3%;
        max-width: 32.3%;

        @media only screen and (max-width: 576px){
        &{
        -ms-flex: 0 0 98%;
        -webkit-flex: 0 0 98%;
        flex: 0 0 98%;
        max-width: 98%;
        margin-bottom: 24px;
        height: 300px;
        text-align: start;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 16.37px 16.37px 20px 16.37px;
        margin: 10px auto 60px auto;
        img{
        min-height: 128.88px;
        height: auto;
        }
        }
        }
        h2{ 
        color: #1C6A69;
        font-family: Noto Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 8px;
        margin-top: 24px;

        @media only screen and (max-width: 992px){
        &{
        font-size: 18px;
        }
        }

        @media only screen and (max-width: 576px){
        &{
        font-size: 16.37px;
        }
        }
        }
        p{
        color: #333;
        font-family: Noto Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        span{
        color: #1C6A69;
        cursor: pointer;
        font-family: Noto Sans;
        font-weight: 500;
        }

        @media only screen and (max-width: 992px){
        font-size: 16px;
        }

        @media only screen and (max-width: 768px){
        font-size: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 13.64px;
        }
        }
        }
        .strategies__list{
        margin-top: 67px;
        }
        .strategies__list__box{
        margin-bottom: 0;
        p{
        color: #333;
        font-family: Noto Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        padding-bottom: 16px;
        @media only screen and (max-width: 992px){
        font-size: 16px;
        }

        @media only screen and (max-width: 768px){
        font-size: 14px;
        }
        @media only screen and (max-width: 576px){
        padding-bottom: 12px;
        padding-left: 15px;
        font-size: 12px;
        }
        }
        ul{
        margin-top: 8px;
        }
        li{
        color: #333;
        font-family: Noto Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        margin-left: 30px;
        padding-bottom: 20px;
        @media only screen and (max-width: 992px){
        font-size: 16px;
        padding-bottom: 16px;
        }

        @media only screen and (max-width: 768px){
        font-size: 14px;
        padding-bottom: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 12px;
        padding-bottom: 12px;
        }

        }
        }

        `;

        export default StrategiesStyle;

import React from 'react';
import AboutCarouselStyle from './AboutCarouselStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import carousel1 from '../../../assets/Images/carousel1.svg';
import carousel2 from '../../../assets/Images/carousel2.svg';
import carousel3 from '../../../assets/Images/carousel3.svg';

const AboutCarousel = () => {

  return (
    <AboutCarouselStyle className={`container about-carousel`}>
        <Carousel
          showArrows={false}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={2100}
        >
          <div className={`container-full about-carousel__box`}>
            <Img image={carousel1} className="img" />
          </div>
          <div className={`container-full about-carousel__box`}>
            <Img image={carousel2} className="img" />
          </div>
          <div className={`container-full about-carousel__box`}>
            <Img image={carousel3} className="img" />
          </div>
        </Carousel>
    </AboutCarouselStyle>
  );
}

export default AboutCarousel;
import React, { useState, useEffect } from 'react';
import ServicesHeroSectionStyle from './ServicesHeroSectionStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Button from '../../atoms/Button/Button';
import Img from '../../atoms/Image/Img';
import moon from '../../../assets/Images/moon.svg';
import subtract from '../../../assets/Images/Subtract.svg';
import intersect from '../../../assets/Images/Intersect.svg';
import fullmoon from '../../../assets/Images/full-moon.svg';



const ServicesHeroSection = () => {

    return (
        <ServicesHeroSectionStyle className={`container services`}>
           <div className={`container-full center services__desc`}>
              <HeaderText value="PEOPLE SERVICE" />
           </div>
           <div className={`container-full services__content`}>
              <div className={`div-md-5 services__content__left`}>
                <HeaderText value='What do we call People Services?' />
              </div>
              <div className={`div-md-7 services__content__right`}>
                <p><span>Talent Assessment, Selection and Recruitment:</span><br/>
                Talent assessment is a process that we use to identify which candidate will perform the best and be the right cultural fit for your organization. It aims to predict a new hire's on-the-job performance and how long they will work at the company.
                </p>
                <p>Highly skilled individuals are vital to organizational success. At Globedge Capital, people are a priority. We are passionate about helping you to source, identify, attract, assess, and recruit the very best talent for the highest-level performance.</p>
              </div>
           </div>
        </ServicesHeroSectionStyle>
    );
}

export default ServicesHeroSection;
        import styled from 'styled-components';

        const WhoWeAreStyle = styled.section`
        background: #F5F5F5;
        box-sizing: border-box;
        padding: 71px 0 41px 0;
        width: 100%;

        @media only screen and (max-width: 992px){
        padding-top: 50px;
        }

        @media only screen and (max-width: 767px){
        padding-top: 24px;
        }

        .who__desc{
        display: none;
        @media only screen and (max-width: 767px){
        color: #333;
        display: block;
        font-family: Noto Sans;
        font-size: 1.5em;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 16px;
        text-align: center;
        width: 100%;
        }
        @media only screen and (max-width: 576px){
        font-size: 16px;
        }
        }

        .who__item{
        margin-bottom: 0;
        .--desktop{
        display: none;
        @media only screen and (min-width: 768px){
        display: block;
        }
        }
        .--mobile{
        display: none;
        @media only screen and (max-width: 767px){
        display: block;
        margin-bottom: 24px;
        }
        }
        h2{
        color: #333;
        font-family: Noto Sans;
        font-size: 2em;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 24px;
        @media only screen and (max-width: 992px){
        margin-bottom: 20px;
        }
        @media only screen and (max-width: 767px){
        display: none;
        }
        }
        p{
        color: #333333;
        font-family: Noto Sans;
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; 
        @media only screen and (max-width: 768px){
        line-height: 160%;
        }
        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        .--first{
        margin-bottom: 30px;
        @media only screen and (max-width: 768px){
        margin-bottom: 25px;
        }
        @media only screen and (max-width: 576px){
        margin-bottom: 20px;
        }
        }
        a{
        display: flex;
        margin-top: 56px;
        @media only screen and (max-width: 992px){
        margin-top: 40px;
        }
        @media only screen and (max-width: 768px){
        margin-top: 30px;
        }
        @media only screen and (max-width: 576px){
        margin-top: 24px;
        }
        p{
        color: #1C6A69;
        font-family: Noto Sans;
        font-size: 1.25em;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-right: 8px;
        @media only screen and (max-width: 576px){
        font-size: 14px;
        }
        }
        img{
        @media only screen and (max-width: 576px){
        height: 16px;
        margin-top: 2px;
        width: 16px;
        }
        }
        }
        }

        `;

        export default WhoWeAreStyle;

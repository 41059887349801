import React from 'react';
import { Route, Routes } from 'react-router-dom';
import About from './components/pages/About/About';
import Contact from './components/pages/Contact/Contact';
import Home from './components/pages/Home/Home';
import LD from './components/pages/LD/LD';
import Operations from './components/pages/Operations/Operations';
import Services from './components/pages/Services/Services';
import Strategy from './components/pages/Strategy/Strategy';
import Technology from './components/pages/Technology/Technology';

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Home />
        }
      />
      <Route
        path="/about"
        element={
          <About />
        }
      />
      <Route
        path="/strategy"
        element={
          <Strategy />
        }
      />
      <Route
        path="/operations"
        element={
          <Operations />
        }
      />
      <Route
        path="/technology"
        element={
          <Technology />
        }
      />
      <Route
        path="/learning-and-development"
        element={
          <LD />
        }
      />
      <Route
        path="/services"
        element={
          <Services />
        }
      />
      <Route
        path="/contact"
        element={
          <Contact />
        }
      />
    </Routes>
  );
}

export default App;

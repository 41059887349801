        import styled from 'styled-components';

        const AboutGoalStyle = styled.section`
        box-sizing: border-box;
        margin-top: 15px;
        padding-bottom: 64px;
        width: 100%;

        .goal__desc{
        p{
        color: #333;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        word-spacing: 5px;
        span{
        color: #333;
        font-family: Noto Sans;
        font-weight: 600;
        }
        }
        }
        .goal__content{
        margin-top: 37px;
        }
        .goal__content__row{
        margin-bottom: 34px;
        @media only screen and (max-width: 768px){
        margin-bottom: 0px;
        }

        &:last-child{
        margin-bottom: 0;

        .goal__content__row__item{

        &:last-child{

        p{
            padding-bottom: 0px;
            }

        }

        }
        }
        }
        .goal__content__row__item{
        margin-bottom: 0;
        h2{
        color: #333;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-top: 29px;
        text-align: center;
        width: 100%;

        @media only screen and (max-width: 992px){
        font-size: 14px;
        }

        @media only screen and (max-width: 768px){
        font-size: 13px;
        }
        
        @media only screen and (max-width: 576px){
        font-size: 12px;
        margin-top: 16px;
        }

        }
        p{
        color: #333;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 1px;
        text-align: center;
        width: 100%;
        @media only screen and (max-width: 992px){
        font-size: 14px;
        }

        @media only screen and (max-width: 768px){
        font-size: 13px;
        margin-bottom: 24px;
        }
        
        @media only screen and (max-width: 576px){
        font-size: 12px;
        margin-bottom: 24px;
        }

        }

        img{
        width: 80px;
        height: 80px;
        @media only screen and (max-width: 992px){
        width: 60px;
        height: 60px;
        }

        @media only screen and (max-width: 768px){
        width: 50px;
        height: 50px;
        }

        @media only screen and (max-width: 576px){
        width: 40px;
        height: 40px;
        }
        }
        }
        .goal__footer{
        margin-top: 76px;
        p{
        color: #4F4F4F;
        text-align: center;
        font-family: Noto Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        @media only screen and (max-width: 992px){
        font-size: 20px;
        }

        @media only screen and (max-width: 768px){
        font-size: 18px;
        }

        @media only screen and (max-width: 576px){
        font-size: 16px;
        }
        }
        }

        `;

        export default AboutGoalStyle;

        import styled from 'styled-components';

        const AboutHeroSectionStyle = styled.section`
        box-sizing: border-box;
        margin-top: 120px;
        width: 100%;

        @media only screen and (max-width: 576px){
        &{
        margin-top: 110px;
        }
        }


        .about__desc{
        h2{
        color: #828282;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.47px;
        text-transform: uppercase;
        margin: 0px 0px;

        @media only screen and (max-width: 992px){
        margin: 20px;
        }

        @media only screen and (max-width: 768px){
        margin: 0px 0px 16px 0px;
        font-size: 12px;
        }

        @media only screen and (max-width: 576px){
        margin:  0px;
        }

        }
        }
        .about__content{
        margin-top: 20px;
        }
        .about__content__left{
        margin-bottom: 0;
        }
        .about__content__left{
        p{
        color: #828282;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin-bottom: 16px;  
        @media only screen and (max-width: 992px){
        font-size: 12px;
        }

        @media only screen and (max-width: 768px){
        font-size: 11px;

        }

        @media only screen and (max-width: 576px){
        font-size: 10px;
        }      
        }
        h2{
        color: #1C6A69;
        font-family: Noto Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;

        @media only screen and (max-width: 992px){
        font-size: 24px;
        }

        @media only screen and (max-width: 768px){
        font-size: 18px;
        }

        @media only screen and (max-width: 576px){
        font-size: 16px;
        margin-bottom: 16px;
        }
        }
        }
        .about__content__right{
        margin-bottom: 0;
        p{
        color: #333;
        font-family: Noto Sans;
        font-size: 16px;   
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        margin-bottom: 30px;
        &:last-child{
        margin-bottom: 0;
        }

        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        }

        @media only screen and (max-width: 768px){
        font-size: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        }
        `;

        export default AboutHeroSectionStyle;

        import styled from 'styled-components';

        const WhyChooseUsStyle = styled.section`
        box-sizing: border-box;
        padding: 80px 15px 62px 15px;
        @media only screen and (max-width: 991px){
        padding-top: 70px;
        }
        @media only screen and (max-width: 767px){
        padding-bottom: 46px;
        padding-top: 53px;
        }

        .choose__desc{
        h2{
        color: #333;
        font-family: Noto Sans;
        font-size: 2em;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media only screen and (max-width: 576px){
        font-size: 20px;
        }
        }
        }

        .choose__content{
        margin-top: 50px;
        @media only screen and (max-width: 767px){
        margin-top: 41px;
        }
        }

        .choose__content__item{
        margin-bottom: 0;
        @media only screen and (max-width: 768px){
        margin-bottom: 30px;
        &:last-child{
        margin-bottom: 0;
        }
        }
        @media only screen and (max-width: 576px){
        margin-bottom: 24px;
        &:last-child{
        margin-bottom: 0;
        }
        }
        }
        .choose__content__item__icon img{
        @media only screen and (max-width: 992px){
        width: 60px;
        }

        @media only screen and (max-width: 767px){
        width: 50px;
        }
        @media only screen and (max-width: 576px){
        width: 40px;
        }
        }
        .choose__content__item__text{
        margin-top: 29px;
        @media only screen and (max-width: 767px){
        margin-top: 16px;
        }
        p{
        color: #333;
        text-align: center;
        font-family: Noto Sans;
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        text-align: center;
        width: 100%;
        @media only screen and (max-width: 768px){
        font-size: 14px;
        }
        @media only screen and (max-width: 576px){
        font-size: 12px;

        }
        }
        }

        `;

        export default WhyChooseUsStyle;

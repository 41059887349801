import styled from 'styled-components';

const NavbarStyle = styled.div`
    background: white;
    
    padding-top: 4px;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 1400px) { 
        padding-bottom: 16px;
    }

    @media only screen and (max-width: 1200px){
        .logo img{
            height: auto;
            width: 200px;
        }
    }

    @media only screen and (max-width: 992px){
        .logo img{
            height: auto;
            width: 150px;
        }
    }

    @media only screen and (max-width: 576px){
        .logo img{
            height: auto;
            width: 130px;
        }
    }
    
    .navlinks{
        align-self: center;
        display: flex;
    }

    @media only screen and (max-width: 1400px) { 
        .navlinks{
            opacity: 0;
            display: none;
        }
    }
    .active{
        box-sizing: border-box;
        border-bottom: 1px solid #3CE05A;
        color: #1C6A69;
        font-family: Noto Sans;
        font-size: 1em;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-right: 24px;
        padding: 8px;
    }
    .navlinks__item{
        box-sizing: border-box;
        color: #4F4F4F;
        font-family: Noto Sans;
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 24px;
        padding: 8px;
        &:nth-of-type(7){
            margin-right: 36.96px;
        }
        &:hover{
            border-bottom: 1px solid #3CE05A;
            color: #1C6A69;
            font-weight: 600;
        }
    }
    .hamburger{
        display: none;
        @media screen and (max-width: 1400px) {
            &{
                align-content: center;
                align-self: center;
                background: #E2EFEF;
                border-radius: 8px;
                cursor: pointer;
                height: 40px;
                display: flex;
                justify-content: center;
                margin-top: 2px;
                position: relative;
                width: 40px;
                z-index: 5000; 
            }
            
        }
    }
    
`;

export default NavbarStyle;

    import styled from 'styled-components';

    const VisionMissionStyle = styled.section`
    background: #F8F8F8;
    box-sizing: border-box;
    margin-top: 5px;
    padding: 40px 0 38px 0;
    width: 100%;

    .qualities__desc{
    h2{
    color: #828282;
    font-family: Noto Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    font-variant: all-small-caps;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    @media only screen and (max-width: 992px){
    font-size: 16px;
    }

    @media only screen and (max-width: 768px){
    font-size: 14px;
    }

    @media only screen and (max-width: 576px){
    font-size: 12px;
    }
    }
    }
    .qualities__quote{
    margin-top: 51px;
    p{
    color: #333;
    text-align: center;
    font-family: Noto Sans;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%; /* 36px */
    @media only screen and (max-width: 992px){
    font-size: 18px;
    }

    @media only screen and (max-width: 576px){
    font-size: 14px;
    }
    }
    }
    .qualities__content{
    margin-top: 49px;
    }
    .qualities__content__desc{
    p{
    color:  #333;
    text-align: center;
    font-family: Noto Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%; 
    span{
    color: #333;
    font-family: Noto Sans;
    font-weight: 600;
    }

    @media only screen and (max-width: 992px){
    font-size: 16px;
    }

    @media only screen and (max-width: 768px){
    font-size: 14px;
    }

    @media only screen and (max-width: 576px){
    font-size: 12px;
    }
    }
    }
    .qualities__content__row{
    margin-top: 51px;
    }
    .qualities__content__row__item{
    margin-bottom: 0;
    img{
    width: 80px;
    height: 80px;
    @media only screen and (max-width: 992px){
    width: 60px;
    height: 60px;
    }

    @media only screen and (max-width: 768px){
    width: 50px;
    height: 50px;
    }

    @media only screen and (max-width: 576px){
    width: 40px;
    height: 40px;
    }
    }
    }
    .qualities__content__row__item__text{
    margin-top: 29px;

    @media only screen and (max-width: 768px){
    margin-top: 14px;
    }

    @media only screen and (max-width: 576px){
    margin-top: 0px;
    }

    p{
    color: #333;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    text-align: center;

    @media only screen and (max-width: 992px){
    font-size: 14px;
    }

    @media only screen and (max-width: 768px){
    font-size: 13px;
    margin-bottom: 24px;
    }

    @media only screen and (max-width: 576px){
    font-size: 12px;
    margin-top: 14px;
    margin-bottom: 24px;
    }
    }
    }


    `;

    export default VisionMissionStyle;

import styled from 'styled-components';

const StrategyCarouselStyle = styled.div`
    margin-top: 64px;
    width: 100%;
    @media only screen and (max-width: 576px){
    &{
    margin-top: 32px;
    }
    }

    .ld-carousel__box{
        margin-bottom: 56px;
        .img{
            width: 100% !important;
        }
    }
    .carousel-root {
        width: 100% !important;
        margin: auto !important;
        position: relative;
      }
      
    .carousel .slide {
        height: 100%;
    }

    .carousel{
        position: relative;
    }

    .carousel .control-dots {
        // background: green;
        // height: 1%;
        // transform: translateY(20px);
    }

    .carousel .control-dots .dot {
        box-shadow: none !important;
        background: #F2F2F2 !important;
        outline: 0;
        opacity: 1;
    }

    .carousel .control-dots .dot.selected{
        background: #3CE05A !important;
    }

    .carousel.carousel-slider .control-arrow {
        opacity: 1 !important;
        background: transparent !important;
        height: 50px !important;
        position: absolute;
        top: 35% !important;
        width: 48px;
        border-radius: 50px;
        border: 1px solid #3CE05A;
    }

    .control-prev.control-arrow:before{
        border-right: 8px solid #3CE05A;
    }

    .control-next.control-arrow:before{
        border-left: 8px solid #3CE05A;
    }

    
 
`;

export default StrategyCarouselStyle;

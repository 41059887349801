import React, { useState, useEffect } from 'react';
import OperationsHeroSectionStyle from './OperationsHeroSectionStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Button from '../../atoms/Button/Button';
import Img from '../../atoms/Image/Img';
import moon from '../../../assets/Images/moon.svg';
import subtract from '../../../assets/Images/Subtract.svg';
import intersect from '../../../assets/Images/Intersect.svg';
import fullmoon from '../../../assets/Images/full-moon.svg';



const OperationsHeroSection = () => {

    return (
        <OperationsHeroSectionStyle className={`container operations`}>
           <div className={`container-full center operations__desc`}>
              <HeaderText value="operations" />
           </div>
           <div className={`container-full operations__content`}>
              <div className={`div-md-5 operations__content__left`}>
                <HeaderText value='What do we call Operations Management?' />
              </div>
              <div className={`div-md-7 operations__content__right`}>
                <Text value='The way a business is operated is one of the factors that contributes hugely to the overall success of a company. It is essential to have effective operations management to ensure your business continues to operate and produce results that lead to company goals and objectives being met as defined in your strategy. At Globedge Capital, we will demonstrate to you some business operations such as planning, supervising and organizing production, manufacturing or the provision of other services that will help you to maximize the profit of your business.' />
              </div>
           </div>
        </OperationsHeroSectionStyle>
    );
}

export default OperationsHeroSection;
    import styled from 'styled-components';

    const BenefitsStyle = styled.section`
    background: #F8F8F8;
    box-sizing: border-box;
    margin-top: 30px;
    padding: 57px 0 100px 0;
    width: 100%;

    .benefits__desc{
    p{
    color: #4F4F4F;
    font-family: Noto Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    @media only screen and (max-width: 992px){
    font-size: 18px;
    }

    @media only screen and (max-width: 768px){
    font-size: 16px;
    }

    @media only screen and (max-width: 576px){
    font-size: 14px;
    }
    }
    }
    .benefits__content{
    margin-top: 72px;
    }
    .benefits__content__row{
    margin-bottom: 24px;
    @media only screen and (max-width: 768px){
    margin-bottom: 0px;
    }
    &:last-child{
    margin-bottom: 0;
    }
    }
    .benefits__content__row__item{
    margin-bottom: 0;
    p{
    color: #333;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    margin-top: 29px;
    text-align: center;
    width: 100%;
    @media only screen and (max-width: 992px){
    font-size: 14px;
    }

    @media only screen and (max-width: 768px){
    font-size: 13px;
    margin-top: 14px;
    margin-bottom: 24px;
    }

    @media only screen and (max-width: 576px){
    margin-top: 14px;
    margin-bottom: 24px;
    font-size: 12px;
    }
    }

    img{
    width: 80px;
    height: 80px;

    @media only screen and (max-width: 992px){
    width: 60px;
    height: 60px;
    }

    @media only screen and (max-width: 768px){
    width: 50px;
    height: 50px;
    }


    @media only screen and (max-width: 576px){
    width: 40px;
    height: 40px;
    }
    }

    }




    `;

    export default BenefitsStyle;

import styled from 'styled-components';

const ContactFormStyle = styled.div`
    width: 100%;

    .form{
        form{
            width: 100%;
        }
    }
    
    .form__row{
        margin-bottom: 24px;
        @media only screen and (max-width: 575px){
            margin-bottom: 16px;
        }
        &:last-child{
            margin-bottom: 0;
        }
        .div-md-6{
            -ms-flex: 0 0 48.8%;
            -webkit-flex: 0 0 48.8%;
            flex: 0 0 48.8%;
            max-width: 48.8%;
            @media only screen and (max-width: 575px){
                -ms-flex: 0 0 100%;
                -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                &:last-child{
                    margin-top: 16px;
                }
            }
        }
    }
    .form__row__item{
        margin-bottom: 0;
        p{
            color: #000;
            font-family: Noto sans;
            font-size: 1em;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 16px;
            @media only screen and (max-width: 768px){
                margin-bottom: 10px;
            }
            @media only screen and (max-width: 575px){
                color: #000;
                font-family: Noto sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 8px;
            }
        }
        .input{
            background: #F8F8F8;
            border: 1px solid rgba(119, 119, 119, 0.40);
            border-radius: 8px;
            color: #777;
            font-family: Noto sans;
            font-size: 1em;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            height: 56px;
            padding-left: 24px;
            position: relative;
            width: 100%;
            &::placeholder{
                color: #777;
                font-family: Noto sans;
                font-size: 1em;
                font-style: normal;
                font-weight: 400;
            }
            @media only screen and (max-width: 575px){
                font-size: 14px;
                height: 48px;
            }
        }
        select{
            appearance: none;
            background: transparent;
            color: #777;
            font-family: Noto sans;
            font-size: 1em;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-top: 14px;
            width: 100%;
        }
        img{
            margin: 16px 24px 0 0;
            position: absolute;
            right: 0;
            top: 0;
            @media only screen and (max-width: 575px){
                margin: 12px 20px 0 0;
            }
        }
        textarea{
            height: 179px !important;
            padding-top: 16px;
        }
    }
    .form__action{
        margin-top: 56px;
        @media only screen and (max-width: 575px){
            margin-top: 19px;
        }
        .button{
            background: #1C6A69;
            border-radius: 8px;
            color: #FFF;
            font-family: Noto Sans;
            font-size: 1em;
            font-style: normal;
            font-weight: 800;
            height: 64px;
            line-height: 150%;
            margin-bottom: 0;
            width: 100%;
            @media only screen and (max-width: 575px){
                font-size: 14px;
                height: 48px;
            }
        }
    }
`;

export default ContactFormStyle;

import styled from 'styled-components';

const AboutTemplateStyle = styled.div`


 .nav{
   background: white;
   position: fixed;
   z-index: 5000;
   box-sizing: border-box;
 }

 /* .testimonials{
   background: white;
   box-sizing: border-box;
   padding: 30px 0 30px 0;
 } */
 .contact{
  background: white;
  box-sizing: border-box;
  padding: 92px 0 41px 0;
 }
`;

export default AboutTemplateStyle;

import React from 'react';
import AboutGoalStyle from './AboutGoalStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import goal from '../../../assets/Images/goal.svg';
import technology from '../../../assets/Images/technology-goal.svg';
import client from '../../../assets/Images/client.svg';
import strategy from '../../../assets/Images/strategy-operations.svg';
import workforce from '../../../assets/Images/workforce.svg';
import stakeholder from '../../../assets/Images/stakeholder.svg';

const AboutGoal = () => {

  return (
    <AboutGoalStyle className={`container goal`}>
      <div className={`container-full goal__desc`}>
        <p>With a reputation for excellence and executing actionable plans yielding transformative results, 
           our primary focus is <span>bringing efficiency</span> to <span>business operations, sustainable values to customers, </span>
           and <span>productivity &amp; profitability to business shareholders. </span>
        </p>
      </div>
      <div className={`container-full goal__content`}>
        <div className={`container-full goal__content__row`}>
          <div className={`div-md-3 goal__content__row__item`}>
            <div className={`container-full center`}>
              <Img image={goal} />
              <HeaderText value='Business Goal' />
              <Text value='To transform businesses.' />
            </div>
          </div>
          <div className={`div-md-3 goal__content__row__item`}>
            <div className={`container-full center`}>
              <Img image={technology} />
              <HeaderText value='Technology Goal' />
              <Text value='To automate, organize, and protect.' />
            </div>
          </div>
          <div className={`div-md-3 goal__content__row__item`}>
            <div className={`container-full center`}>
              <Img image={client} />
              <HeaderText value='Client Goal' />
              <Text value='To build lasting relationships.' />
            </div>
          </div>
        </div>
        <div className={`container-full goal__content__row`}>
          <div className={`div-md-3 goal__content__row__item`}>
            <div className={`container-full center`}>
              <Img image={strategy} />
              <HeaderText value='Strategy &amp; Operations' />
              <Text value='X-ray, redefine strategy, and remodel processes.' />
            </div>
          </div>
          <div className={`div-md-3 goal__content__row__item`}>
            <div className={`container-full center`}>
              <Img image={workforce} />
              <HeaderText value='Workforce Mission' />
              <Text value='To train personnel, charge with 
              global and industry knowledge.' />
            </div>
          </div>
          <div className={`div-md-3 goal__content__row__item`}>
            <div className={`container-full center`}>
              <Img image={stakeholder} />
              <HeaderText value='Stakeholders Goal' />
              <Text value='To deliver value.' />
            </div>
          </div>
        </div>
      </div>
      <div className={`container-full center goal__footer`}>
        <Text value='“This is our goal. This is our forte. This is our achievement.”' />
      </div>
    </AboutGoalStyle>
  );
}

export default AboutGoal;
import React from 'react';
import VisionMissionStyle from './VisionMissionStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';

const VisionMission = () => {

  return (
    <VisionMissionStyle className={`container-full vision`}>
      <div className={`container vision__container`}>
        <div className={`div-sm-12 vision__container__box`}>
          <HeaderText value='Our Vision' />
          <Text value='“Helping our clients unlock insights about their future, equipping with the right knowledge, journeying together, and arriving at the desired future together.”' />
        </div>
        <div className={`div-sm-12 vision__container__box`}>
          <HeaderText value='Our Mission Statement' />
          <Text value='“Raising global leader through conscious problem solving, and by developing lasting client relation, deliver values to all stakeholders.”' />
        </div>
      </div>
    </VisionMissionStyle>
  );
}

export default VisionMission;
        import styled from 'styled-components';

        const OurClientsStyle = styled.div`
        width: 100%;
        .testimonials__desc{
        h2{
        color: #4F4F4F;
        text-align: center;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;

        @media only screen and (max-width: 767px){
        font-size: 14px;
        }
        }
        }
        .testimonials__carousel{
        margin-top: 32px;
        }

        .carousel-root {
        width: 100% !important;
        margin: auto !important;
        position: relative;
        }

        .carousel .slide {
        height: 100%;
        }

        .carousel .slide img {
        width: 339px !important;
        border-radius: 50% !important;
        }

        .carousel{
        position: relative;
        }



        .carousel .control-dots .dot {
        box-shadow: none !important;
        background: #F2F2F2 !important;
        outline: 0;
        opacity: 1;
        }

        .carousel .control-dots .dot.selected{
        background: #3CE05A !important;
        }

        .carousel.carousel-slider .control-arrow {
        opacity: 1 !important;
        background: transparent !important;
        height: 50px !important;
        position: absolute;
        top: 35% !important;
        width: 48px;
        border-radius: 50px;
        border: 1px solid #3CE05A;
        @media only screen and (max-width: 767px){
        display: none;
        }
        }

        .control-prev.control-arrow:before{
        border-right: 8px solid #3CE05A;
        }

        .control-next.control-arrow:before{
        border-left: 8px solid #3CE05A;
        }

        .testimonials__carousel__box{
        margin: auto;
        }

        .testimonials__carousel__box__desc{
        p{
            color: #333;
            text-align: center;
            font-family: Noto Sans;
            font-size: 16px;
            font-style: italic;
            font-weight: 400;
            line-height: 175%;

        @media only screen and (max-width: 992px){
            font-size: 14px;
        }

        @media only screen and (max-width: 576px){
            font-size: 12px;
        }
        }
        }
        .testimonials__carousel__box__avatar{
        margin-top: 32px;
        img{
        height: 56px;
        width: 56px;
        }
        }
        .testimonials__carousel__box__title{
        margin-bottom: 28px;
        margin-top: 16px;
        p{
        color: #333;
        text-align: center;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        width: 100%;
        @media only screen and (max-width: 576px){
        font-size: 14px;
        }
        &:last-child{
        color: #4F4F4F;
        font-weight: 400;
        @media only screen and (max-width: 576px){
            font-size: 12px;
        }
        }
        }
        }

        `;

        export default OurClientsStyle;

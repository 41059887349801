import React from 'react';
import WhyChooseUsStyle from './WhyChooseUsStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import who from '../../../assets/Images/who-we-are.svg';
import profit from '../../../assets/Images/Profit Growth.svg';
import strength from '../../../assets/Images/Strenght.svg';
import land from '../../../assets/Images/Land Use.svg';

const WhyChooseUs = () => {

  return (
    <WhyChooseUsStyle className={`container choose`}>
      <div className={`container-full center choose__desc`}>
        <HeaderText value='Why choose us?' />
      </div>
      <div className={`container-full choose__content`}>
        <div className={`div-md-4 choose__content__item`}>
          <div className={`container-full center choose__content__item__icon`}>
            <Img image={profit} alt="profit" />
          </div>
          <div className={`container-full choose__content__item__text`}>
            <Text value='We help you identify key steps to take your business to the next level and deliver optimum value to customers.' />
          </div>
        </div>
        <div className={`div-md-4 choose__content__item`}>
          <div className={`container-full center choose__content__item__icon`}>
            <Img image={strength} alt="strength" />
          </div>
          <div className={`container-full choose__content__item__text`}>
            <Text value='We optimize your strengths and compensates for or eliminate your weaknesses.' />
          </div>
        </div>
        <div className={`div-md-4 choose__content__item`}>
          <div className={`container-full center choose__content__item__icon`}>
            <Img image={land} alt="allocation" />
          </div>
          <div className={`container-full choose__content__item__text`}>
            <Text value='We would effectively allocate resources for your business activities,' />
          </div>
        </div>
      </div>
    </WhyChooseUsStyle>
  );
}

export default WhyChooseUs;
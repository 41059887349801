import styled from 'styled-components';

const ContactTemplateStyle = styled.div`
 .nav{
    background: white;
    position: fixed;
    z-index: 5000;
 }

 .contact{
    background: white;
    box-sizing: border-box;
    padding: 120px 0 41px 0;
 }
`;

export default ContactTemplateStyle;

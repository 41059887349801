import React, { useEffect, useState } from 'react';
import BusinessOperationsStyle from './BusinessOperationsStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import processes from '../../../assets/Images/processes.svg';
import personnel from '../../../assets/Images/personnel.svg';
import equipment from '../../../assets/Images/equipment.svg';
import location from '../../../assets/Images/location-operations.svg';
import goals from '../../../assets/Images/Goals.svg';
import communication from '../../../assets/Images/Communication.svg';
import company from '../../../assets/Images/Company Culture.svg';
import digitizer from '../../../assets/Images/Digitizer.svg';
import employees from '../../../assets/Images/Employees.svg';
import evaluate from '../../../assets/Images/evaluate.svg';
import informed from '../../../assets/Images/Stay Informed.svg';
import organized from '../../../assets/Images/Organized board.svg';

const BusinessOperations = () => {
  

  return (
    <BusinessOperationsStyle className={`container business`}>
      <div className={`container-full business__desc`}>
        <HeaderText value='Business Operations' />
        <h3>
          Business operations prioritize actions and systems for planning, productivity, and profits. 
          Investing in them improves productivity, reduces costs, and encourages innovation. At Globedge, 
          we have  identified four constant elements across industries - <span>Science and Technology, Retail, and Manufacturing.</span>
        </h3>
        <Text value='The elements are :' />
      </div>
      <div className={`container-full business__elements`}>
        <div className={`div-md-3 business__elements__item`}>
          <Img image={processes} className="img" />
        </div>
        <div className={`div-md-3 business__elements__item`}>
          <Img image={personnel} className="img" />
        </div>
        <div className={`div-md-3 business__elements__item`}>
          <Img image={equipment} className="img" />
        </div>
        <div className={`div-md-3 business__elements__item`}>
          <Img image={location} className="img" />
        </div>
      </div>
      <div className={`container-full business__help`}>
        <div className='container-full business__help__desc'>
          <Text value='The Globedge team will also help you:' />
        </div>
        <div className='container-full business__help__content'>
          <div className={`container-full business__help__content__row`}>
            <div className={`div-md-3 business__help__content__row__item`}>
              <div className='container-full center'>
                <Img image={goals} />
                <Text value='Set attainable goals' />
              </div>
            </div>
            <div className={`div-md-3 business__help__content__row__item`}>
              <div className='container-full center'>
                <Img image={informed} />
                <Text value='Stay informed' />
              </div>
            </div>
            <div className={`div-md-3 business__help__content__row__item`}>
              <div className='container-full center'>
                <Img image={digitizer} />
                <Text value=' Digitize your business' />
              </div>
            </div>
          </div>

          <div className={`container-full business__help__content__row`}>
            <div className={`div-md-3 business__help__content__row__item`}>
              <div className='container-full center'>
                <Img image={company} />
                <Text value='Invest in a strong company culture' />
              </div>
            </div>
            <div className={`div-md-3 business__help__content__row__item`}>
              <div className='container-full center'>
                <Img image={organized} />
                <Text value='Stay organized' />
              </div>
            </div>
            <div className={`div-md-3 business__help__content__row__item`}>
              <div className='container-full center'>
                <Img image={communication} />
                <Text value='Practice communication' />
              </div>
            </div>
          </div>

          <div className={`container-full business__help__content__row`}>
            <div className={`div-md-3 business__help__content__row__item`}>
              <div className='container-full center'>
                <Img image={evaluate} />
                <Text value='Re-evaluate operations' />
              </div>
            </div>
            <div className={`div-md-3 business__help__content__row__item`}>
              <div className='container-full center'>
                <Img image={employees} />
                <Text value='Recruit high-quality employees' />
              </div>
            </div>
            <div className={`div-md-3 business__help__content__row__item`}></div>
          </div>
        </div>
      </div>
      
    </BusinessOperationsStyle>
  );
}

export default BusinessOperations;
import React, { useEffect } from 'react';
import OperationsTemplateStyles from './OperationsTemplateStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Navbar from '../../organisms/Navbar/Navbar';
import Footer from '../../organisms/Footer/Footer';
import Contact from '../../organisms/Contact/Contact';
import OperationsHeroSection from '../../organisms/OperationsHeroSection/OperationsHeroSection';
import OperationsCarousel from '../../organisms/OperationsCarousel/OperationsCarousel';
import Benefits from '../../organisms/Benefits/Benefits';
import BusinessOperations from '../../organisms/BusinessOperations/BusinessOperations';

const OperationsTemplate = () => {

  return (
    <>
      <OperationsTemplateStyles>
        <div className={`container-full`}>
          <div className={`container-full nav`}>
            <Navbar />
          </div>
          <OperationsHeroSection />
          <OperationsCarousel />
          <div className='container quotes'>
            <div className={`container-full quotes__desc`}>
              <HeaderText value='“In the end, all business operations can be reduced to three words: people, product, and profits.” – Lee Lacocca' />
            </div>
            <div className={`container-full quotes__text`}>
              <Text value='Together, we will help your operations management team to focus on measuring and analyzing internal processes for their efficiency and effectiveness.' />
            </div>
          </div>
          <Benefits />
          <BusinessOperations />
          <section className='container contact'>
            <Contact />   
          </section>
          <Footer />
        </div>
      </OperationsTemplateStyles>
    </>
  );
};

export default OperationsTemplate;

    import styled from 'styled-components';

    const ContactStyle = styled.div`
    width: 100%;
    .contact__desc{
    h2{
    color: #333;
    font-family: Noto Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    width: 75%;
    @media only screen and (max-width: 992px){
        font-size: 22px;
    }
    @media only screen and (max-width: 767px){
        font-size: 18px;
    }
    @media only screen and (max-width: 575px){
        font-size: 14px;
    }
    }
    }
    .contact__body{
    flex-direction: row-reverse;
    margin-top: 80px;
    @media only screen and (max-width: 575px){
    margin-top: 26px;
    }
    }
    .contact__body__info{
    background: #F8F8F8;
    box-sizing: border-box;
    margin-bottom: 0;
    padding: 32px 118px 0 32px;
    -ms-flex: 0 0 45%;
    -webkit-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
    @media only screen and (max-width: 1199px){
    -ms-flex: 0 0 53%;
    -webkit-flex: 0 0 53%;
    flex: 0 0 53%;
    max-width: 53%;
    }
    @media only screen and (max-width: 991px){
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 40px;
    }
    @media only screen and (max-width: 575px){
    background: transparent;
    padding: 0;
    }
    }
    .contact__body__info__desc{
    h2{
    color: #333;
    font-family: Noto Sans;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    @media only screen and (max-width: 575px){
        font-size: 14px;
    }
    }
    }
    .contact__body__info__content{
    margin-top: 41px;
    @media only screen and (max-width: 575px){
    margin-top: 24px;
    }
    }
    .contact__body__info__content__row{
    justify-content: normal;
    margin-bottom: 40px;
    @media only screen and (max-width: 575px){
    margin-bottom: 30px;
    }
    }
    .contact__body__info__content__row__img{
    margin-right: 24px;
    width: 6%;
    @media only screen and (max-width: 575px){
    width: 3%;
    img{
        height: 20px;
        width: 20px;
    }
    }
    }
    .contact__body__info__content__row__text{
    width: 87%;
    h2{
    color: var(--secondary, #333);
    font-family: Noto Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 16px;
    @media only screen and (max-width: 575px){
        font-size: 14px;
        margin-bottom: 8px;
    }
    }
    p{
    color: #777;
    font-family: Noto Sans;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    letter-spacing: -0.8px;
    span{
        color: #1C6A69;
        font-family: Noto Sans;
        text-decoration-line: underline;
    }
    }
    }
    .contact__body__form{
    -ms-flex: 0 0 51%;
    -webkit-flex: 0 0 51%;
    flex: 0 0 51%;
    max-width: 51%;
    margin-bottom: 0;
    @media only screen and (max-width: 1199px){
    -ms-flex: 0 0 42%;
    -webkit-flex: 0 0 42%;
    flex: 0 0 42%;
    max-width: 42%;
    }
    @media only screen and (max-width: 991px){
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 5px;
    }
    }
    `;

    export default ContactStyle;

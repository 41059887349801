        import styled from 'styled-components';

        const FooterStyle = styled.footer`
        background: #333;
        box-sizing: border-box;
        padding: 80px 15px;
        width: 100%;
        @media only screen and (max-width: 767px){
        padding: 25px 15px 39px 15px;
        }

        .footer__img{
        @media only screen and (max-width: 767px){
        img{
        width: 170px;
        }
        }
        @media only screen and (max-width: 576px){
        img{
        width: 120px;
        }
        }
        }

        .footer__nav{
        margin-top: 32px;
        @media only screen and (max-width: 767px){
        margin-top: 24px;
        }
        a{
        color: #FFF;
        font-family: Noto Sans;
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-right: 32px;
        &:last-child{
        margin-bottom: 0;
        margin-right: 0;
        }
        @media only screen and (max-width: 767px){
        margin-bottom: 32px;
        margin-right: 0;
        width: 100%;
        text-align: center;
        }
        @media only screen and (max-width: 576px){
        font-size: 14px;
        }
        }
        }
        .footer__socials{
        margin-top: 64px;
        @media only screen and (max-width: 767px){
        margin-top: 50px;
        }
        @media only screen and (max-width: 576px){
        margin-top: 32px;
        }
        img{
        margin-right: 24px;
        &:last-child{
        margin-right: 0;
        }
        }
        }
        .footer__bottom{
        border-top: 1px solid #E0E0E0;
        box-sizing: border-box;
        margin-top: 64px;
        padding-top: 32px;
        @media only screen and (max-width: 767px){
        margin-top: 50px;
        }
        @media only screen and (max-width: 576px){
        margin-top: 32px;
        }
        }
        .footer__bottom__left{
        @media only screen and (max-width: 767px){
        margin-bottom: 24px;
        width: 100%;
        }
        p{
        color: #FFF;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; 
        @media only screen and (max-width: 767px){
        width: 100%;
        text-align: center;
        }
        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        }
        .footer__bottom__right{
        @media only screen and (max-width: 767px){
        margin: auto;
        }
        a{
        color: #FFF;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-right: 24px;
        text-decoration-line: underline;
        &:last-child{
        margin-right: 0;
        }
        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        }

        `;

        export default FooterStyle;

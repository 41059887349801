import React, { useState, useEffect } from 'react';
import ContactHeroSectionStyle from './ContactHeroSectionStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Button from '../../atoms/Button/Button';
import Img from '../../atoms/Image/Img';
import contactBg from '../../../assets/Images/contact-bg.svg';



const ContactHeroSection = () => {

    return (
        <ContactHeroSectionStyle className={`container contact-hero`}>
            <div className={`container-full center contact__desc`}>
            <HeaderText value="CONTACT US" />
            </div>
           <div className={`container-full center contact-hero__box`}>
              <HeaderText value='Contact Us' />
              <Text value='Please feel free to contact us with any enquiries you may have.' />
           </div>
        </ContactHeroSectionStyle>
    );
}

export default ContactHeroSection;
    import styled from 'styled-components';

    const HomeTemplateStyle = styled.div`
    .nav{
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    position: fixed;
    z-index: 5000;
    }

    .testimonials{
    background: white;
    box-sizing: border-box;
    padding: 30px 0 30px 0;
    @media only screen and (max-width: 767px){
    padding-bottom: 20px;
    }
    }
    .contact{
    background: white;
    box-sizing: border-box;
    padding: 75px 15px 41px 15px;
    @media only screen and (max-width: 767px){
    padding-top: 0;
    }
    @media only screen and (max-width: 575px){
    padding-bottom: 30px;
    }
    }
    `;

    export default HomeTemplateStyle;

import React from 'react';
import QualitiesStyle from './QualitiesStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import profit from '../../../assets/Images/Profit Growth.svg';
import strength from '../../../assets/Images/Strenght.svg';
import land from '../../../assets/Images/Land Use.svg';

const Qualities = () => {

  return (
    <QualitiesStyle className={`container-full qualities`}>
      <div className={`container center qualities__desc`}>
        <HeaderText value='Why Choose Globedge Capital for Your Business Strategy?' />
      </div>
      <div className={`container center qualities__quote`}>
        <p> “Forget about your competitors, just focus on your customers.”  <br/> Jack Ma</p>
      </div>
      <div className={`container qualities__content`}>
        <div className={`container-full center qualities__content__desc`}>
          <p>For this reason that at Globedge we are <span>customer-centric:</span></p>
        </div>
        <div className={`container-full qualities__content__row`}>
          <div className={`div-md-4 qualities__content__row__item`}>
            <div className={`container-full center qualities__content__row__item__icon`}>
              <Img image={profit} />
            </div>
            <div className={`container-full qualities__content__row__item__text`}>
              <Text value='We help you identify key steps to take your business to the next level and deliver optimum value to customers.' />
            </div>
          </div>
          <div className={`div-md-4 qualities__content__row__item`}>
            <div className={`container-full center qualities__content__row__item__icon`}>
              <Img image={strength} />
            </div>
            <div className={`container-full qualities__content__row__item__text`}>
              <Text value='We optimize your strengths and compensates for or eliminate your weaknesses.' />
            </div>
          </div>
          <div className={`div-md-4 qualities__content__row__item`}>
            <div className={`container-full center qualities__content__row__item__icon`}>
              <Img image={land} />
            </div>
            <div className={`container-full qualities__content__row__item__text`}>
              <Text value='We would effectively allocate resources for your business activities.' />
            </div>
          </div>
        </div>
      </div>
    </QualitiesStyle>
  );
}

export default Qualities;
import React, { useState } from 'react';
import StrategiesStyle from './StrategiesStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import businessObject from '../../../assets/Images/business-objective.svg';
import coreValues from '../../../assets/Images/core-values.svg';
import swotAnalysis from '../../../assets/Images/swot-analysis.svg';
import operationalEfficiency from '../../../assets/Images/operational-efficiency.svg';
import resourceAllocation from '../../../assets/Images/resource-allocation.svg';
import measurement from '../../../assets/Images/measurement.svg';
import DetailsModal from '../DetailsModal/DetailsModal';
import { Carousel } from 'react-responsive-carousel';

import businessObjectBg from '../../../assets/Images/business-objective-bg.svg';
import coreValuesBg from '../../../assets/Images/core-value-bg.svg';
import swotAnalysisBg from '../../../assets/Images/swot-analysis-bg.svg';
import operationalEfficiencyBg from '../../../assets/Images/operationa-efficiency-bg.svg';
import resourceAllocationBg from '../../../assets/Images/resource-allocation-bg.svg';
import measurementBg from '../../../assets/Images/measurement-bg.svg';

const Strategies = () => {
  const [toggleDetailsModal, setToggleDetailsModal] = useState(false);
  const [bg, setBg] = useState('');
  const [text, setText] = useState('');
  const [headerText, setHeaderText] = useState('');

  const showDetailsModal = async (bg: string, text: string, headerText: string) => {
    await setBg(bg);
    await setText(text);
    await setHeaderText(headerText);
    setToggleDetailsModal(true)
  }

  const hideDetailsModal = () => {
    setToggleDetailsModal(false)
  }

  const renderDetailsModal = () => {
    if (toggleDetailsModal) {
      return (
        <DetailsModal handleClose={hideDetailsModal}
          headerText={headerText}
          text={text}
          bg={bg}
        />
      )
    }
  }

  return ( 
    <>
      {renderDetailsModal()}
      <StrategiesStyle className={`container strategies`}>
        <div className={`container-full strategies__desc`}>
          <HeaderText value='Six Key Components of Business Strategy We Will Engineer to Transform Your Business for Global Positioning.' />
        </div>
        <div className={`container-full  strategies__content`}>
          <div className={`container-full strategies__content__row`}>
            <div className={`div-md-4 strategies__content__row__item`}>
              <Img image={businessObject} className="img" />
              <HeaderText value='Vision and Business Objective  ' />
              <p>With a vision well defined for the direction of your business, you can create clear instructions...
                <span onClick={() => showDetailsModal(businessObjectBg, 'With a vision well defined for the direction of your business, you can create clear instructions in your strategy for what needs to be done and who is responsible for completing each step. This brings accountability on the table, and the spirit of ownership for the personnel on such missions.', 'Vision and Business Objective  ')}>read more</span></p>
            </div>
            <div className={`div-md-4 strategies__content__row__item`}>
              <Img image={coreValues} className="img" />
              <HeaderText value='Core Values ' />
              <p>Core values are birthed from your vision and mission, as an organization. Your top-level executives...
                <span onClick={() => showDetailsModal(coreValuesBg, 'Core values are birthed from your vision and mission, as an organization. Your top-level executives, as well as departments gets absolute clarity about what should and should not be done, according to your core values. It helps everyone stay on the same page and with the same goals.', 'Core Values')}>read more</span></p>
            </div>
            <div className={`div-md-4 strategies__content__row__item`}>
              <Img image={swotAnalysis} className="img" />
              <HeaderText value='SWOT Analysis' />
              <p> SWOT stands for strengths, weakness, opportunities, and threats. This is constant in every business...
                <span onClick={() => showDetailsModal(swotAnalysisBg, 'SWOT stands for strengths, weakness, opportunities, and threats. This is constant in every business strategy, as it allows you to rely and build on your strengths and use them as an advantage while also identifying the areas of weakness and threats within the industry you operate in.', 'SWOT Analysis')}>read more</span></p>
            </div>
          </div>
          <div className={`container-full strategies__content__row`}>
            <div className={`div-md-4 strategies__content__row__item`}>
              <Img image={operationalEfficiency} className="img" />
              <HeaderText value='Operational Efficiency (Tactics)' />
              <p>Together, we would help articulate the operational details of your business activities...
                <span onClick={() => showDetailsModal(operationalEfficiencyBg, 'Together, we would help articulate the operational details of your business activities, how the work should be done in order to maximize efficiency. The personnel saddled with core operational functions understand what needs to be done, saving time and effort. This is the application and adoption of lean principle as a culture to your organization.', 'Operational Efficiency (Tactics)')}>read more</span></p>
            </div>
            <div className={`div-md-4 strategies__content__row__item`}>
              <Img image={resourceAllocation} className="img" />
              <HeaderText value='Resource Allocation Plan' />
              <p>After defining your organization’s vision and strategy, next is the implementation...
                <span onClick={() => showDetailsModal(resourceAllocationBg, 'After defining your organization’s vision and strategy, next is the implementation. Further, the success of your vision is hugely determined by the quality of human resources available for the strategy execution. Resource Allocation Plan includes where you will find the required resources to complete the plan, how the resources will be allocated and who is responsible for the doing so and the outcome of the results. In this regard, you will be able to identify areas where you need to add more (or as the case may be, remove) resources or to reassign them where necessary in order to complete your projects.', 'Resource Allocation Plan')}>read more</span></p>
            </div>
            <div className={`div-md-4 strategies__content__row__item`}>
              <Img image={measurement} className="img" />
              <HeaderText value='Measurement' />
              <p> If you can’t measure it, you can’t manage it”. The words of Peter Drucker...
                <span onClick={() => showDetailsModal(measurementBg, '“If you can’t measure it, you can’t manage it”. The words of Peter Drucker. Measurement includes a way to track the company’s output, evaluating how it is performing in relation to the target that were set prior to launching the strategy. This helps you stay on track with deadlines and goals, as well as addressing budgetary concerns.', 'Measurement')}>read more</span></p>
            </div>
          </div>
        </div>

        <div className='container strategy-item__carousel'>
        <Carousel
          showArrows={false}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={2100}
        >
                <div className={`div-md-4 strategies__content__row__item`}>
              <Img image={businessObject} className="img" />
              <HeaderText value='Vision and Business Objective  ' />
              <p>With a vision well defined for the direction of your business, you can create clear instructions...
                <span onClick={() => showDetailsModal(businessObjectBg, 'With a vision well defined for the direction of your business, you can create clear instructions in your strategy for what needs to be done and who is responsible for completing each step. This brings accountability on the table, and the spirit of ownership for the personnel on such missions.', 'Vision and Business Objective  ')}>read more</span></p>
            </div>
            <div className={`div-md-4 strategies__content__row__item`}>
              <Img image={coreValues} className="img" />
              <HeaderText value='Core Values ' />
              <p>Core values are birthed from your vision and mission, as an organization. Your top-level executives...
                <span onClick={() => showDetailsModal(coreValuesBg, 'Core values are birthed from your vision and mission, as an organization. Your top-level executives, as well as departments gets absolute clarity about what should and should not be done, according to your core values. It helps everyone stay on the same page and with the same goals.', 'Core Values')}>read more</span></p>
            </div>
            <div className={`div-md-4 strategies__content__row__item`}>
              <Img image={swotAnalysis} className="img" />
              <HeaderText value='SWOT Analysis' />
              <p> SWOT stands for strengths, weakness, opportunities, and threats. This is constant in every business...
                <span onClick={() => showDetailsModal(swotAnalysisBg, 'SWOT stands for strengths, weakness, opportunities, and threats. This is constant in every business strategy, as it allows you to rely and build on your strengths and use them as an advantage while also identifying the areas of weakness and threats within the industry you operate in.', 'SWOT Analysis')}>read more</span></p>
            </div>

            <div className={`div-md-4 strategies__content__row__item`}>
              <Img image={operationalEfficiency} className="img" />
              <HeaderText value='Operational Efficiency (Tactics)' />
              <p>Together, we would help articulate the operational details of your business activities...
                <span onClick={() => showDetailsModal(operationalEfficiencyBg, 'Together, we would help articulate the operational details of your business activities, how the work should be done in order to maximize efficiency. The personnel saddled with core operational functions understand what needs to be done, saving time and effort. This is the application and adoption of lean principle as a culture to your organization.', 'Operational Efficiency (Tactics)')}>read more</span></p>
            </div>
            <div className={`div-md-4 strategies__content__row__item`}>
              <Img image={resourceAllocation} className="img" />
              <HeaderText value='Resource Allocation Plan' />
              <p>After defining your organization’s vision and strategy, next is the implementation...
                <span onClick={() => showDetailsModal(resourceAllocationBg, 'After defining your organization’s vision and strategy, next is the implementation. Further, the success of your vision is hugely determined by the quality of human resources available for the strategy execution. Resource Allocation Plan includes where you will find the required resources to complete the plan, how the resources will be allocated and who is responsible for the doing so and the outcome of the results. In this regard, you will be able to identify areas where you need to add more (or as the case may be, remove) resources or to reassign them where necessary in order to complete your projects.', 'Resource Allocation Plan')}>read more</span></p>
            </div>
            <div className={`div-md-4 strategies__content__row__item`}>
              <Img image={measurement} className="img" />
              <HeaderText value='Measurement' />
              <p> If you can’t measure it, you can’t manage it”. The words of Peter Drucker...
                <span onClick={() => showDetailsModal(measurementBg, '“If you can’t measure it, you can’t manage it”. The words of Peter Drucker. Measurement includes a way to track the company’s output, evaluating how it is performing in relation to the target that were set prior to launching the strategy. This helps you stay on track with deadlines and goals, as well as addressing budgetary concerns.', 'Measurement')}>read more</span></p>
            </div>
        </Carousel>
        </div>

        <div className={`container-full strategies__list`}>
          <div className={`div-md-6 strategies__list__box`}>
            <Text value='A few examples of these strategies may include the following:' />
            <ul>
              <li>Product or Service Differentiation</li>
              <li>Improvement on Customer Service and Customer Retention</li>
              <li>Pricing Strategies</li>
              <li>Most Innovative Product or Services</li>
              <li>Grow Sales from New Products and Cross-selling</li>
              <li>Identifying New Market Penetration Opportunities</li>
              <li>Technological Advantage</li>
              <li>Market Share Growth and Sustainability</li>
            </ul>
          </div>
        </div>
      </StrategiesStyle>
    </>
  );
}

export default Strategies;
import React from 'react';
import TechnologyContentStyle from './TechnologyContentStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import automate from '../../../assets/Images/automate.svg';
import organize from '../../../assets/Images/organize.svg';
import protect from '../../../assets/Images/protect.svg';
import goals from '../../../assets/Images/Goals.svg';
import changes from '../../../assets/Images/Changes.svg';
import collaboration from '../../../assets/Images/Collaboration.svg';
import delegation from '../../../assets/Images/delegation.svg';
import methods from '../../../assets/Images/Methods.svg';
import partner from '../../../assets/Images/Partner Channels.svg';
import resources from '../../../assets/Images/Resources.svg';
import risk from '../../../assets/Images/Risk Management.svg';
import roadmap from '../../../assets/Images/roadmap.svg';
import task from '../../../assets/Images/Task List.svg';
import timeline from '../../../assets/Images/timeline.svg';

const TechnologyContent = () => {

  return (
    <TechnologyContentStyle className={`container technology`}>
      <div className={`container-full technology__desc`}>
        <Text value="Technology is essential for businesses in all areas, from communication to operations. It's crucial for survival in the market and accelerating business growth. Business technology refers to all tools that help manage operational processes, track workflows, and maintain data and records." />
      </div>
      <div className={`container-full technology__focus`}>
        <div className={`container-full technology__focus__desc`}>
          <HeaderText value='The focus of business technology is to ' />
        </div>
        <div className={`container-full technology__focus__content`}>
          <div className={`div-md-4 technology__focus__content__item`}>
            <Img image={automate} className="img" />
          </div>
          <div className={`div-md-4 technology__focus__content__item`}>
            <Img image={organize} className="img" />
          </div>
          <div className={`div-md-4 technology__focus__content__item`}>
            <Img image={protect} className="img" />
          </div>
        </div>
      </div>
      <div className={`container-full technology__list`}>
        <Text value='Some examples of business technology include:' />
        <ul>
          <li>· Inventory Management </li>
          <li>· Hiring and General Resource Management </li>
          <li>· Communication (Productivity Tools) </li>
          <li>· Payroll </li>
          <li>· Computer, Laptop, Mail and Phone Systems </li>
          <li>· Cloud Storage </li>
          <li>· Accounting Software </li>
          <li>· Customer Relationship Management (CRM) Software </li>
          <li>· Task Allocation </li>
        </ul>
      </div>
      <div className={`container-full technology__list --second`}>
        <Text value='There are numerous advantages of the application of business technology which are not limited to the following:' />
        <ul>
          <li>1. Diversified marketing options</li>
          <li>2. Improved communication methods  </li>
          <li>3. Better security to company data and infrastructure </li>
          <li>4. Prompt financial services </li>
          <li>5. Improved operations efficiency </li>
          <li>6. Wide range research opportunities</li>
        </ul>
      </div>
      <div className={`container-full technology__roadmap`}>
        <div className={`container-full technology__roadmap__desc`}>
          <HeaderText value="Globedge can help your business adapt to changing global operations by implementing the right technology and creating a customized technology roadmap. Our experience and expertise can position you for local and global opportunities." />
        </div>
        <div className={`container-full technology__roadmap__explanation`}>
          <div className={`div-md-5 technology__roadmap__explanation__left`}>
            <HeaderText value='Technology Roadmap' />
          </div>
          <div className={`div-md-7 technology__roadmap__explanation__right`}>
            <Text value='A technology roadmap, also called an IT roadmap, is a planning tool that professionals use to organize specific projects for technological improvement within a company. Globedge Capital ensures that the implementation of technological changes enhances operational processes and safeguards critical data.' />
          </div>
        </div>
        <div className={`container-full technology__roadmap__content`}>
          <div className={`container-full technology__roadmap__content__desc`}>
            <p>A typical Globedge’s technological roadmap for businesses which improves <span>communications, sets schedules, and considers consequences,</span> has components of:</p>
          </div>
          <div className={`container-full technology__roadmap__content__group`}>
            <div className={`container-full technology__roadmap__content__group__row`}>
              <div className={`div-md-4 technology__roadmap__content__group__row__item`}>
                <div className='container-full center'>
                  <Img image={goals} />
                  <Text value='Goals and initiatives where we define the achievements your company hopes to make.' />
                </div>
              </div>
              <div className={`div-md-4 technology__roadmap__content__group__row__item`}>
                <div className='container-full center'>
                  <Img image={changes} />
                  <Text value='Description of changes taking place within your organization.' />
                </div>
              </div>
              <div className={`div-md-4 technology__roadmap__content__group__row__item`}>
                <div className='container-full center'>
                  <Img image={timeline} />
                  <Text value='Deadlines on the advanced increment timelines.' />
                </div>
              </div>
            </div>

            <div className={`container-full technology__roadmap__content__group__row`}>
              <div className={`div-md-3 technology__roadmap__content__group__row__item`}>
                <div className='container-full center'>
                  <Img image={resources} />
                  <Text value='Resources available for the technological advancement project.' />
                </div>
              </div>
              <div className={`div-md-3 technology__roadmap__content__group__row__item`}>
                <div className='container-full center'>
                  <Img image={risk} />
                  <Text value='Risk factor associated with internal and external challenges your company expect on the change.' />
                </div>
              </div>
              <div className={`div-md-3 technology__roadmap__content__group__row__item`}>
                <div className='container-full center'>
                  <Img image={task} />
                  <Text value='Status reports that keep all the stakeholders stay informed and to help determine next steps.' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`container-full technology__roadmap__content --second`}>
          <div className={`container-full technology__roadmap__content__desc`}>
            <p> With the Globedge team onboard, we will assist with the guidelines on how to create the technology roadmap. See a few tips (steps) below:</p>
          </div>
          <div className={`container-full technology__roadmap__content__group`}>
            <div className={`container-full technology__roadmap__content__group__row`}>
              <div className={`div-md-4 technology__roadmap__content__group__row__item`}>
                <div className='container-full center'>
                  <Img image={goals} />
                  <Text value='1. Identify your goals' />
                </div>
              </div>
              <div className={`div-md-4 technology__roadmap__content__group__row__item`}>
                <div className='container-full center'>
                  <Img image={collaboration} />
                  <Text value='2. Determine your participants' />
                </div>
              </div>
              <div className={`div-md-4 technology__roadmap__content__group__row__item`}>
                <div className='container-full center'>
                  <Img image={methods} />
                  <Text value='3. Decide on a format' />
                </div>
              </div>
            </div>

            <div className={`container-full technology__roadmap__content__group__row`}>
              <div className={`div-md-3 technology__roadmap__content__group__row__item`}>
                <div className='container-full center'>
                  <Img image={roadmap} />
                  <Text value='4. Choose the section of your roadmap' />
                </div>
              </div>
              <div className={`div-md-3 technology__roadmap__content__group__row__item`}>
                <div className='container-full center'>
                  <Img image={delegation} />
                  <Text value='5. Assign responsibilities' />
                </div>
              </div>
              <div className={`div-md-3 technology__roadmap__content__group__row__item`}>
                <div className='container-full center'>
                  <Img image={partner} />
                  <Text value='6. Share with the team' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TechnologyContentStyle>
  );
}

export default TechnologyContent;
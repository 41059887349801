import React from 'react';
import LDCarouselStyle from './L&DCarouselStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import carousel13 from '../../../assets/Images/carousel13.svg';
import carousel14 from '../../../assets/Images/carousel14.svg';
import carousel15 from '../../../assets/Images/carousel15.svg';

const LDCarousel = () => {

  return (
    <LDCarouselStyle className={`container ld-carousel`}>
        <Carousel
          showArrows={false}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={2100}
        >
          <div className={`container-full ld-carousel__box`}>
            <Img image={carousel13} className="img" />
          </div>
          <div className={`container-full ld-carousel__box`}>
            <Img image={carousel14} className="img" />
          </div>
          <div className={`container-full ld-carousel__box`}>
            <Img image={carousel15} className="img" />
          </div>
        </Carousel>
    </LDCarouselStyle>
  );
}

export default LDCarousel;
import React, {useState, useEffect} from 'react';
import DetailsModalStyle from './DetailsModalStyle';
import HeaderText from '../../atoms/HeaderText/Text';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Img';
import close from '../../../assets/Images/close.svg';

type DetailsModalProps = {
    handleClose: Function,
    bg: string,
    headerText: string,
    text: string
}

const DetailsModal = ({handleClose, bg, headerText, text}: DetailsModalProps) => {

    return (
      <DetailsModalStyle className={`container-full`}>
        <div className={`container-full center modal-container`}>
          <div className={`container-full modal-container__box`}>
            <div className={`container-full modal-container__box__bg`} style={{backgroundImage: `url(${bg})`}}> </div>
            <div className={`container-full modal-container__box__content`}>
              <HeaderText value={headerText} />
              <Text value={text} />
            </div>
          </div>  
          <div className={`modal-container__close`}>
            <Image image={close} onClick={() => handleClose()} />
          </div>
        </div>
      </DetailsModalStyle>
    );
}

export default DetailsModal;
import React, { useEffect } from 'react';
import ContactTemplateStyles from './ContactTemplateStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Navbar from '../../organisms/Navbar/Navbar';
import Footer from '../../organisms/Footer/Footer';
import Contact from '../../organisms/Contact/Contact';
import ServicesHeroSection from '../../organisms/ServicesHeroSection/ServicesHeroSection';
import ServicesCarousel from '../../organisms/ServicesCarousel/ServicesCarousel';
import ServicesDevelopment from '../../organisms/ServicesDevelopment/ServicesDevelopment';
import ContactHeroSection from '../../organisms/ContactHeroSection/ContactHeroSection';
import Faq from '../../organisms/Faq/Faq';

const ContactTemplate = () => {

  return (
    <>
      <ContactTemplateStyles>
        <div className={`container-full`}>
          <div className={`container-full nav`}>
            <Navbar />
          </div>
          <ContactHeroSection />
          <section className={`container-full contact`}>
            <Contact />
          </section>
          <Faq/>
          <Footer />
        </div>
      </ContactTemplateStyles>
    </>
  );
};

export default ContactTemplate;

import React from 'react';
import ServicesDevelopmentStyle from './ServicesDevelopmentStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import decision from '../../../assets/Images/decision-making.svg';
import communication from '../../../assets/Images/communincation-services.svg';
import culture from '../../../assets/Images/culture.svg';
import performance from '../../../assets/Images/performance.svg';
import mentoring from '../../../assets/Images/mentoring.svg';
import organization from '../../../assets/Images/organization.svg';
import trust from '../../../assets/Images/trust.svg';
import change from '../../../assets/Images/change.svg';
import creativity from '../../../assets/Images/creativity.svg';
import emotional from '../../../assets/Images/emotional-intelligence.svg';

const ServicesDevelopment = () => {

  return (
    <ServicesDevelopmentStyle className={`container development`}>
      <div className={`container-full development__desc`}>
        <HeaderText value='Leadership Development' />
        <p>Effective leaders in a company help achieve goals and maximize potential. Identifying their development areas can enhance efficiency and 
          positivity. Prioritizing and addressing these needs positions organizations for global success. 
          Purposeful initiatives target areas for improvement, promoting strong leadership.
          <span> Here is a list of 10 leadership development areas: </span></p>
      </div>
      <div className={`container-full development__content`}>
          <div className={`div-md-4 development__content__row__item`}>
            <Img image={decision} className="img" />
          </div>
          <div className={`div-md-4 development__content__row__item`}>
            <Img image={communication} className="img" />
          </div>
          <div className={`div-md-4 development__content__row__item`}>
            <Img image={culture} className="img" />
          </div>

          <div className={`div-md-4 development__content__row__item`}>
            <Img image={performance} className="img" />
          </div>
          <div className={`div-md-4 development__content__row__item`}>
            <Img image={mentoring} className="img" />
          </div>
          <div className={`div-md-4 development__content__row__item`}>
            <Img image={organization} className="img" />
          </div>


          <div className={`div-md-4 development__content__row__item`}>
            <Img image={trust} className="img" />
          </div>
          <div className={`div-md-4 development__content__row__item`}>
            <Img image={change} className="img" />
          </div>
          <div className={`div-md-4 development__content__row__item`}>
            <Img image={creativity} className="img" />
          </div>
 

          <div className={`div-md-4 development__content__row__item`}>
            <Img image={emotional} className="img" />
          </div>

      </div>
    </ServicesDevelopmentStyle>
  );
}

export default ServicesDevelopment;
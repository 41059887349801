import React from 'react';
import LDBenefitsStyle from './LDBenefitsStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import workplace from '../../../assets/Images/workplace.svg';
import productivity from '../../../assets/Images/Productivity.svg';
import improvement from '../../../assets/Images/Improvement.svg';
import shield from '../../../assets/Images/shield.svg';
import skills from '../../../assets/Images/Skills.svg';
import innovation from '../../../assets/Images/Innovation.svg';
import competence from '../../../assets/Images/Competence.svg';
import happy from '../../../assets/Images/happy.svg';
import increase from '../../../assets/Images/Increase Graph.svg';

const LDBenefits = () => {

  return (
    <LDBenefitsStyle className={`container ld-benefits`}>
      <div className={`container-full ld-benefits__desc`}>
        <Text value='Start our learning and development program to;' />
      </div>
      <div className={`container-full ld-benefits__content`}>
        <div className={`container-full ld-benefits__content__row`}>
          <div className={`div-md-3 ld-benefits__content__row__item`}>
            <div className='container-full center'>
              <Img image={workplace} />
              <Text value='Increase in workplace productivity' />
            </div>
          </div>
          <div className={`div-md-3 ld-benefits__content__row__item`}>
            <div className='container-full center'>
              <Img image={productivity} />
              <Text value='Upskill and reskill revolution' />
            </div>
          </div>
          <div className={`div-md-3 ld-benefits__content__row__item`}>
            <div className='container-full center'>
              <Img image={improvement} />
              <Text value='Improve workplace culture ' />
            </div>
          </div>
        </div>

        <div className={`container-full ld-benefits__content__row`}>
          <div className={`div-md-3 ld-benefits__content__row__item`}>
            <div className='container-full center'>
              <Img image={shield} />
              <Text value='Reinforce business values' />
            </div>
          </div>
          <div className={`div-md-3 ld-benefits__content__row__item`}>
            <div className='container-full center'>
              <Img image={skills} />
              <Text value='Develop new skills' />
            </div>
          </div>
          <div className={`div-md-3 ld-benefits__content__row__item`}>
            <div className='container-full center'>
              <Img image={innovation} />
              <Text value='Facilitate great innovation' />
            </div>
          </div>
        </div>

        <div className={`container-full ld-benefits__content__row`}>
          <div className={`div-md-3 ld-benefits__content__row__item`}>
            <div className='container-full center'>
              <Img image={competence} />
              <Text value='Ensure employee competence on the job' />
            </div>
          </div>
          <div className={`div-md-3 ld-benefits__content__row__item`}>
            <div className='container-full center'>
              <Img image={happy} />
              <Text value='Ensure employees’ job satisfaction' />
            </div>
          </div>
          <div className={`div-md-3 ld-benefits__content__row__item`}>
            <div className='container-full center'>
              <Img image={increase} />
              <Text value='Increase company’s overall productivity.' />
            </div>
          </div>
        </div>
      </div>
    </LDBenefitsStyle>
  );
}

export default LDBenefits;
import React, { useEffect } from 'react';
import ServicesTemplateStyles from './ServicesTemplateStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Navbar from '../../organisms/Navbar/Navbar';
import Footer from '../../organisms/Footer/Footer';
import Contact from '../../organisms/Contact/Contact';
import ServicesHeroSection from '../../organisms/ServicesHeroSection/ServicesHeroSection';
import ServicesCarousel from '../../organisms/ServicesCarousel/ServicesCarousel';
import ServicesDevelopment from '../../organisms/ServicesDevelopment/ServicesDevelopment';

const ServicesTemplate = () => {

  return (
    <>
      <ServicesTemplateStyles>
        <div className={`container-full`}>
          <div className={`container-full nav`}>
            <Navbar />
          </div>
          <ServicesHeroSection />
          <ServicesCarousel />
          <section className={`container services__quote`}>
            <div className={`container-full center services__quote__desc`}>
              <HeaderText value='L&amp;D Strategy' />
            </div>
            <div className={`container-full center services__quote__text`}>
              <Text value='“If you don’t develop people, how can you expect your business to grow?” asks Paul Gahleitner, Chief HR Officer at Porsche Holding Salzburg (PHS).' />
            </div>
            <div className={`container-full services__quote__info`}>
              <Text value='People drive business. It doesn’t happen by itself and it demands focus and planning. That’s why we encourage investing a lot of money in talent development – because it’s the people who make the difference, and the ROI is worth more than taking the risk of keeping unmotivated employees in your organization.' />
              <Text value='The more we learn, the more we understand that there is always room for growth. In recent years, organizations have shifted their learning and development strategy to a continuous approach that fosters a sense of ingenuity and engagement that benefits businesses and employees alike.' />
            </div>
          </section>
          <ServicesDevelopment />
          <section className={`container-full contact`}>
            <Contact />
          </section>
          <Footer />
        </div>
      </ServicesTemplateStyles>
    </>
  );
};

export default ServicesTemplate;

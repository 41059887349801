import styled from 'styled-components';

const MobileNavbarStyle = styled.header`
    display: none;
    @media only screen and (max-width: 1400px) {
        position: absolute;
        position: fixed;
        top: 0;
        left: 0;
        display: inline-block;
        min-height: 100vh;
        background: #1C6A69;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 70px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;     
        z-index: 3000; 
        width: 100%;
        .menu .menu-items .list-item {
            list-style: none;
            box-sizing: border-box; 
            margin-bottom: 20px;
        }
        .menu .menu-items .list-item p {
            display: inline-block;
            color: white;
            font-size: 1.2em;
            font-family: Noto Sans;
        }
    }    
`;

export default MobileNavbarStyle;

import React, { useEffect } from 'react';
import StrategyTemplateStyles from './StrategyTemplateStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Navbar from '../../organisms/Navbar/Navbar';
import Footer from '../../organisms/Footer/Footer';
import Testimonials from '../../organisms/Testimonials/Testimonials';
import Contact from '../../organisms/Contact/Contact';
import StrategyHeroSection from '../../organisms/StrategyHeroSection/StrategyHeroSection';
import StrategyCarousel from '../../organisms/StrategyCarousel/StrategyCarousel';
import Qualities from '../../organisms/Qualities/Qualities';
import Strategies from '../../organisms/Strategies/Strategies';

const StrategyTemplate = () => {

  return (
    <>
      <StrategyTemplateStyles>
        <div className={`container-full`}>
          <div className={`container-full nav`}>
            <Navbar />
          </div>
          <StrategyHeroSection />
          <StrategyCarousel />
          <Qualities />
          <Strategies />
          <section className='container contact'>
            <Contact />   
          </section>
          <Footer />
        </div>
      </StrategyTemplateStyles>
    </>
  );
};

export default StrategyTemplate;

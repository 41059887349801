import styled from 'styled-components';

const OurClientsStyle = styled.section`
background: #1C6A69;
box-sizing: border-box;
padding: 40px 0 95px 0;

@media only screen and (max-width: 768px){
padding: 32px 0 56px 0;
}

.clients__desc{
h2{
color: #F2F2F2;
font-family: Noto Sans;
font-size: 1.25em;
font-style: normal;
font-weight: 500;
line-height: 150%;
letter-spacing: 1.4px;
text-transform: uppercase;
@media only screen and (max-width: 576px){
font-size: 12px;
}
}
}
.clients__logos{
flex-wrap: nowrap;
justify-content: normal;
margin-top: 80px;
overflow: scroll;
scroll-behavior: smooth;
@media only screen and (max-width: 767px){
margin-top: 56px;
}
img{
margin-right: 39px;
@media only screen and (max-width: 767px){
height: 80px;
margin-right: 32px;
}
}
}
.clients__logos::-webkit-scrollbar{
display: none;
}

    .clients__desc{
        h2{
            color: #F2F2F2;
            font-family: Noto Sans;
            font-size: 1.25em;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: 1.4px;
            text-transform: uppercase;
            @media only screen and (max-width: 576px){
                font-size: 12px;
            }
        }
    }
    .clients__logos{
        flex-wrap: nowrap;
        justify-content: normal;
        margin-top: 80px;
        overflow: scroll;
        scroll-behavior: smooth;
        @media only screen and (max-width: 768px){
            margin-top: 56px;
        }
        img{
            background: white;
            height: 120px;
            margin-right: 39px;
            @media only screen and (max-width: 768px){
                height: 80px;
                margin-right: 32px;
            }
        }
    }
    .clients__logos::-webkit-scrollbar{
        display: none;
    }
 
`;

export default OurClientsStyle;

import React, { useState, useEffect } from 'react';
import StrategyHeroSectionStyle from './StrategyHeroSectionStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Button from '../../atoms/Button/Button';
import Img from '../../atoms/Image/Img';
import moon from '../../../assets/Images/moon.svg';
import subtract from '../../../assets/Images/Subtract.svg';
import intersect from '../../../assets/Images/Intersect.svg';
import fullmoon from '../../../assets/Images/full-moon.svg';



const StrategyHeroSection = () => {

    return (
        <StrategyHeroSectionStyle className={`container strategy`}>
           <div className={`container-full center strategy__desc`}>
              <HeaderText value="Business Strategy" />
           </div>
           <div className={`container-full strategy__content`}>
              <div className={`div-md-5 strategy__content__left`}>
                <HeaderText value='What do we call Business Strategy?' />
              </div>
              <div className={`div-md-7 strategy__content__right`}>
                <Text value='A business strategy is an outline of the actions and decisions a company plans to take to reach its goals and objectives. It defines the direction your organization must take to reach its goals, which can help guide the decision-making process primarily for an internal process in human resources and talent management. Your company strategy unifies the operability of your different departments, ensuring common decisions support to the overall direction and goal of the company.' />
              </div>
           </div>
        </StrategyHeroSectionStyle>
    );
}

export default StrategyHeroSection;
import React from 'react';
import TestimonialsStyle from './TestimonialsStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import fola from '../../../assets/Images/fola.svg';
import helen from '../../../assets/Images/helen.svg';
import olaitan from '../../../assets/Images/olaitan.svg';
import charles from '../../../assets/Images/charles.svg';
import mohammed from '../../../assets/Images/mohammed.svg';
import jemilatu from '../../../assets/Images/jemilatu.svg';
import basil from '../../../assets/Images/basil.svg';
import leke from '../../../assets/Images/leke.svg';

const Testimonials = () => {

  return (
    <TestimonialsStyle className={`container testimonials`}>
      <div className={`container-full center testimonials__desc`}>
        <HeaderText value='Testimonials on Trainings Delivered' />
      </div>
      <div className={`container testimonials__carousel`}>
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
        >
          <div className='div-md-6 testimonials__carousel__box'>
            <div className={`container-full center testimonials__carousel__box__desc`}>
              <Text value='“I want to tell you how well the team did with Michael Owokade in their one-on-one sessions. They went into their sessions very open minded! After each session, I read their body language and they all came out of the room as if they had seen the “light”.' />
            </div>
            <div className={`container-full center testimonials__carousel__box__avatar`}>
              <Img image={fola} alt="fola" />
            </div>
            <div className={`container center testimonials__carousel__box__title`}>
              <Text value='Forosola Ajayi' />
              <Text value='MTN, Nigeria' />
            </div>
          </div>
          <div className='div-md-6 testimonials__carousel__box'>
            <div className={`container-full center testimonials__carousel__box__desc`}>
              <Text value='“The feedback from yesterday’s class was absolutely splendid! The participants had nothing but positive things to say about the content and you, the instructor. I’m sure that the changes we implemented made a very positive difference.”' />
            </div>
            <div className={`container-full center testimonials__carousel__box__avatar`}>
              <Img image={helen} alt="helen" />
            </div>
            <div className={`container center testimonials__carousel__box__title`}>
              <Text value='Helin C.' />
              <Text value='Federal Internal Revenue Service, FIRS.' />
            </div>
          </div>
          <div className='div-md-6 testimonials__carousel__box'>
            <div className={`container-full center testimonials__carousel__box__desc`}>
              <Text value='“This management session helped me become a better leader, improve my communication with employees, build relationships and be more productive within my team.” I have improved my ability to become conscious of my attitude, identify behavioral styles and have better one-on-one discussions with my team.”' />
            </div>
            <div className={`container-full center testimonials__carousel__box__avatar`}>
              <Img image={olaitan} alt="olaitan" />
            </div>
            <div className={`container center testimonials__carousel__box__title`}>
              <Text value='Olaitan Fagbamila' />
              <Text value='Eko Electricity Distribution Company, EKEDC' />
            </div>
          </div>
          <div className='div-md-6 testimonials__carousel__box'>
            <div className={`container-full center testimonials__carousel__box__desc`}>
              <Text value='“I believe the skill sets reviewed in this program such as human fundamentals, communication tools, and management fundamentals are critical to our success as managers. The instructor was great at explaining the new skills and getting us to participate in activities that helped us learn how to apply them. She also shared several interesting, relevant examples and stories that made the learning experience engaging.”' />
            </div>
            <div className={`container-full center testimonials__carousel__box__avatar`}>
              <Img image={charles} alt="charles" />
            </div>
            <div className={`container center testimonials__carousel__box__title`}>
              <Text value='Charles Phillips' />
              <Text value='AIICO' />
            </div>
          </div>
          <div className='div-md-6 testimonials__carousel__box'>
            <div className={`container-full center testimonials__carousel__box__desc`}>
              <Text value='“This training is easily the best training I have ever attended in the last 5 years. There were no boring moments whatsoever, and the speaker had a wealth of quality knowledge to be shared. A very pleasant & professional speaker.”' />
            </div>
            <div className={`container-full center testimonials__carousel__box__avatar`}>
              <Img image={mohammed} alt="mohammed" />
            </div>
            <div className={`container center testimonials__carousel__box__title`}>
              <Text value='Muhammed Abubakar' />
              <Text value='Zenith Assure' />
            </div>
          </div>
          <div className='div-md-6 testimonials__carousel__box'>
            <div className={`container-full center testimonials__carousel__box__desc`}>
              <Text value='“Globedge is a company with a great master of Project Management. Joining theoretical and practical knowledge, they demonstrate huge experience in software development and expertise in the execution of enterprise applications. Our experience with them is unparalleled. Congratulations and more success.”' />
            </div>
            <div className={`container-full center testimonials__carousel__box__avatar`}>
              <Img image={jemilatu} alt="jemilatu" />
            </div>
            <div className={`container center testimonials__carousel__box__title`}>
              <Text value='Jemilatu Adbulai' />
              <Text value='Stanbic Bank' />
            </div>
          </div>
          <div className='div-md-6 testimonials__carousel__box'>
            <div className={`container-full center testimonials__carousel__box__desc`}>
              <Text value='“Michael Owokade, the CEO of Globedge Capital Limited, ranks top as one of few great professional project managers in Africa. He is a great motivator for the beginners in this discipline in organizations on the continent. I have been privileged to receive training from him and also worked with him directly in Nigeria. I learned to be proactive, organized and creative.”' />
            </div>
            <div className={`container-full center testimonials__carousel__box__avatar`}>
              <Img image={basil} alt="basil" />
            </div>
            <div className={`container center testimonials__carousel__box__title`}>
              <Text value='Basil Anekwe' />
              <Text value='ERL Nigeria' />
            </div>
          </div>
          <div className='div-md-6 testimonials__carousel__box'>
            <div className={`container-full center testimonials__carousel__box__desc`}>
              <Text value='“Globedge are a great people to work with, not only because of their great knowledge and control over the project management practices but also for their capacity to lead productive teams, focused on goals. The opportunity we had to work together was crowned with great success, we achieved all the established goals, benefiting our clients.”' />
            </div>
            <div className={`container-full center testimonials__carousel__box__avatar`}>
              <Img image={leke} alt="leke" />
            </div>
            <div className={`container center testimonials__carousel__box__title`}>
              <Text value='Hon. ‘Leke Ogundimu' />
              <Text value='Tomflims Associates International' />
            </div>
          </div>
          
        </Carousel>
      </div>
    </TestimonialsStyle>
  );
}

export default Testimonials;
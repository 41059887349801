import React, { InputHTMLAttributes }  from 'react';
import InputStyle from './InputStyle';

export interface InputProps extends InputHTMLAttributes<any>{
    useStyle?: boolean
    ref?: any
}

const Input = ({
    value,
    type,
    id,
    onChange,
    onFocus,
    onKeyPress,
    onKeyUp,
    className,
    name,
    placeholder,
    defaultChecked,
    useStyle,
    checked,
    onClick,
    ref,
    required,
    ...props
}: InputProps): JSX.Element => {
    return (
        <>
           { (useStyle === undefined) ? (
                <InputStyle type={type} id={id} defaultChecked={defaultChecked} className={className} value={value} name={name} onChange={onChange} onFocus={onFocus} placeholder={placeholder} onKeyPress={onKeyPress} onKeyUp={onKeyUp} ref={ref} checked={checked} onClick={onClick} required={required} {...props}>
                    
                </InputStyle>
           ) : 
                <input type={type} id={id} className={className} value={value} name={name} onChange={onChange} placeholder={placeholder} onKeyPress={onKeyPress} onKeyUp={onKeyUp} checked={checked} ref={ref} onClick={onClick} required={required} {...props}/>
           }
            
        </>
    );
};

export default Input;
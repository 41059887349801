import React, { useEffect } from 'react';
import TechnologyTemplateStyles from './Learning&DevelopmentTemplateStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Navbar from '../../organisms/Navbar/Navbar';
import Footer from '../../organisms/Footer/Footer';
import Contact from '../../organisms/Contact/Contact';
import LDCarousel from '../../organisms/LDCarousel/L&DCarousel';
import LDExperiencing from '../../organisms/LDExperiencing/LDExperiencing';
import LDBenefits from '../../organisms/LDBenefits/LDBenefits';
import LDGoals from '../../organisms/LDGoals/LDGoals';
import LDDeploy from '../../organisms/LDDeploy/LDDeploy';
import LDMethods from '../../organisms/LDMethods/LDMethods';
import Testimonials from '../../organisms/Testimonials/Testimonials';

const TechnologyTemplate = () => {

  return (
    <>
      <TechnologyTemplateStyles>
        <div className={`container-full`}>
          <div className={`container-full nav`}>
            <Navbar />
          </div>
          <div className={`container ld-hero`}>
            <div className={`container-full center ld-hero__desc`}>
              <HeaderText value='LEARNING &amp; DEVELOPMENT' />
              <Text value='A survey of CEOs found that 80% are worried about the availability of talent with desirable, key skills – and more than half struggle to find competent new talent to fill open roles.' />
            </div>
          </div>
          <LDCarousel />
          <div className={`container ld__hint`}>
            <Text value='Organizations face difficulties in maintaining their competitive edge due to the fast-paced evolution of new technology and related skills. Digital transformation renders some technologies and skills obsolete while creating a demand for new ones, further complicating matters. Additionally, the cutthroat competition for top talent adds to the challenge of hiring and retaining employees to meet organizational needs.' />
          </div>
          <div className={`container ld__hint`}>
            <Text value='Conversely, Organizations fear losing top talent after investing in their training. But investing in employee training is critical for retaining talent, staying competitive, and enhancing productivity, resulting in greater profitability and a better reputation.' />
          </div>
          <div className={`container ld__quotes`}>
            <div className='container-full center ld__quotes__item'>
              <Text value='“The only thing worse than training employees and losing them is to not train them and keep them.” - Zig Ziglar.' />
            </div>
            <div className='container-full center ld__quotes__item'>
              <Text value='“According to 94% of employees, an essential policy that would convince them to stay in the company is training and development”. (Clear Company, 2021)' />
            </div>
            <div className='container-full center ld__quotes__item'>
              <Text value='“76% of employees see training as crucial to their work”. (SHRM, 2022)' />
            </div>
          </div>
          <LDExperiencing />
          <LDBenefits />
          <LDGoals />
          <LDDeploy />
          <LDMethods />
          <section className={`container-full testimonials`}>
            <Testimonials />
          </section>
          <section className={`container-full contact`}>
            <Contact />
          </section>
          <Footer />
        </div>
      </TechnologyTemplateStyles>
    </>
  );
};

export default TechnologyTemplate;

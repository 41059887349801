        import styled from 'styled-components';

        const VisionMissionStyle = styled.section`
        background: #F8F8F8;
        box-sizing: border-box;
        padding: 45px 0;
        width: 100%;

        .vision__container{

        }

        .vision__container__box{
        margin-bottom: 80px;
        &:last-child{
        margin-bottom: 0;
        }
        h2{
        color: #4F4F4F;
        font-family: Noto Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin-bottom: 32px;
        text-align: center;
        width: 100%;
        @media only screen and (max-width: 992px){
        font-size: 26px;
        }

        @media only screen and (max-width: 768px){
        font-size: 20px;
        }

        @media only screen and (max-width: 576px){
        font-size: 16px;
        margin-bottom: 16px;
        }
        }
        p{
        color: #1C6A69;
        text-align: center;
        font-family: Noto Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        margin: auto;
        text-align: center;
        width: 89%;
        @media only screen and (max-width: 992px){
        font-size: 18px;
        }

        @media only screen and (max-width: 768px){
        font-size: 16px;
        }

        @media only screen and (max-width: 576px){
        font-size: 14px;
        }
        }
        }


        `;

        export default VisionMissionStyle;

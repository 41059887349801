          import styled from 'styled-components';

          const OurClientsStyle = styled.section`
          margin-top: 80px;
          width: 100%;

          .business__desc{
          h2{
          color: #1C6A69;
          font-family: Noto Sans;
          font-size: 40px;
          font-style: normal;
          font-weight: 500;
          text-align: start;
          line-height: 150%; /* 60px */
          margin-bottom: 24px;

          @media only screen and (max-width: 992px){
          font-size: 24px;
          }

          @media only screen and (max-width: 768px){
          font-size: 18px;
          }

          @media only screen and (max-width: 576px){
          text-align: center;
          font-size: 16px;
          margin: 0 auto 24px auto;
          }

          }
          h3{
          color: #333;
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 175%; 
          margin-bottom: 40px;
          span{
          font-family: Noto Sans;
          font-weight: 500;
          }

          @media only screen and (max-width: 992px){
          font-size: 15.5px;
          }

          @media only screen and (max-width: 768px){
          font-size: 14px;
          }

          @media only screen and (max-width: 576px){
          font-size: 12px;
          }
          }
          p{
          color: #333;
          font-family: Noto Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 175%;
          @media only screen and (max-width: 992px){
          font-size: 18px;
          }

          @media only screen and (max-width: 576px){
          font-size: 14px;
          }
          }
          }
          .business__elements{
          margin-top: 29px;
          }
          .business__elements__item{
          margin-bottom: 0;
          -ms-flex: 0 0 23.7%;
          -webkit-flex: 0 0 23.7%;
          flex: 0 0 23.7%;
          max-width: 23.7%;
          @media only screen and (max-width: 767px){
          -ms-flex: 0 0 48.7%;
          -webkit-flex: 0 0 48.7%;
          flex: 0 0 48.7%;
          max-width: 48.7%;
          margin-bottom: 8px;
          }
          }
          .business__help{
          margin-top: 64px;
          }
          .business__help__desc{
          p{
          color: #333;
          font-family: Noto Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 175%;
          @media only screen and (max-width: 992px){
          font-size: 18px;
          }

          @media only screen and (max-width: 576px){
          font-size: 14px;
          }
          }
          }
          .business__help__content{
          margin-top: 24px;
          }
          .business__help__content__row{
          margin-bottom: 24px;
          @media only screen and (max-width: 768px){
          margin-bottom: 0px;
          }

          &:last-child{
          margin-bottom: 0;
          }
          }
          .business__help__content__row__item{
          margin-bottom: 0;
          img{
          align-self: center;
          width: 80px;
          height: 80px;
          @media only screen and (max-width: 992px){
          width: 60px;
          height: 60px;
          }

          @media only screen and (max-width: 768px){
          width: 50px;
          height: 50px;
          }
          @media only screen and (max-width: 576px){
          width: 40px;
          height: 40px;
          }
          }
          p{
          color: var(--gray-1, #333);
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 175%; /* 28px */
          margin-top: 29px;
          text-align: center;
          width: 100%;
          
          @media only screen and (max-width: 992px){
          font-size: 14px;
          }

          @media only screen and (max-width: 768px){
          font-size: 13px;
          margin-top: 14px;
          margin-bottom: 24px;
          }

          @media only screen and (max-width: 576px){
          margin-top: 12px;
          margin-bottom: 24px;
          font-size: 12px;
          }
          }
          }

          `;

          export default OurClientsStyle;

        import styled from 'styled-components';

        const ServicesDevelopmentStyle = styled.section`
        margin-top: 105px;
        width: 100%;
        @media only screen and (max-width: 576px){
        margin-top: 50px;
        }

        .development__desc{
        h2{
        color: #1C6A69;
        font-family: Noto Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 48px */
        margin-bottom: 24px;
        @media only screen and (max-width: 992px){
        font-size: 24px;
        }

        @media only screen and (max-width: 768px){
        font-size: 18px;
        }

        @media only screen and (max-width: 576px){
        font-size: 16px;
        }
        }
        p{
        color: #4F4F4F;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; /* 28px */
        span{
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        }

        @media only screen and (max-width: 768px){
        font-size: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }

        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        }

        @media only screen and (max-width: 768px){
        font-size: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        }

        .development__content{
        margin-top: 64px;
        @media only screen and (max-width: 992px){
        gap: 8px;   
        }
        &:last-child{
        margin-bottom: 0;
        }
        }


        .development__content__row__item{
        margin-bottom: 0;
        -ms-flex: 0 0 32.25%;
        -webkit-flex: 0 0 32.25%;
        flex: 0 0 32.25%;
        max-width: 32.25%;
        margin-bottom: 20px;
        @media only screen and (max-width: 992px){
        margin-bottom: 8px;
        }
        @media only screen and (max-width: 767px){
        -ms-flex: 0 0 48.5%;
        -webkit-flex: 0 0 48.5%;
        flex: 0 0 48.5%;
        max-width: 48.5%;
        margin-bottom: 0;
        }
        }




        `;

        export default ServicesDevelopmentStyle;

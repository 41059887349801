      import styled from 'styled-components';

      const AboutTemplateStyle = styled.div`
      .nav{
      background: white;
      position: fixed;
      z-index: 5000;
      }

      .testimonials{
      background: white;
      box-sizing: border-box;
      padding: 30px 0 30px 0;
      @media only screen and (max-width: 767px){
      padding-bottom: 20px;
      }
      }
      .contact{
      background: white;
      box-sizing: border-box;
      padding: 75px 0 41px 0;
      @media only screen and (max-width: 767px){
      padding-top: 0;
      }
      @media only screen and (max-width: 575px){
      padding-bottom: 30px;
      }
      }
      `;

      export default AboutTemplateStyle;

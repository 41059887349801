import React from 'react';
import LDMethodsStyle from './LDMethodsStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import instructor from '../../../assets/Images/instructor.svg';
import technology from '../../../assets/Images/technology-based-training.svg';
import simulators from '../../../assets/Images/simulators.svg';
import job from '../../../assets/Images/job-training.svg';
import coaching from '../../../assets/Images/coaching.svg';
import roleplaying from '../../../assets/Images/roleplaying.svg';
import films from '../../../assets/Images/films.svg';
import caseStudies from '../../../assets/Images/case-studies.svg';



const LDMethods = () => {

  return (
    <LDMethodsStyle className={`container methods`}>
      <div className={`container-full methods__desc`}>
        <HeaderText value='Our training methods are mainly:' />
      </div>
      <div className={`container-full methods__content`}>
          <div className={`div-md-4 methods__content__row__item`}>
            <Img image={instructor} className="img" />
          </div>
          <div className={`div-md-4 methods__content__row__item`}>
            <Img image={technology} className="img" />
          </div>
          <div className={`div-md-4 methods__content__row__item`}>
            <Img image={simulators} className="img" />
          </div>
          <div className={`div-md-4 methods__content__row__item`}>
            <Img image={job} className="img" />
          </div>
          <div className={`div-md-4 methods__content__row__item`}>
            <Img image={coaching} className="img" />
          </div>
          <div className={`div-md-4 methods__content__row__item`}>
            <Img image={roleplaying} className="img" />
          </div>
          <div className={`div-md-4 methods__content__row__item`}>
            <Img image={films} className="img" />
          </div>
          <div className={`div-md-4 methods__content__row__item`}>
            <Img image={caseStudies} className="img" />
          </div>
          <div className={`div-md-4 methods__content__row__item`}>
          </div>
      </div>
    </LDMethodsStyle>
  );
}

export default LDMethods;
        import styled from 'styled-components';

        const LDMethodsStyle = styled.section`
        margin-top: 80px;
        width: 100%;
        @media only screen and (max-width: 576px){
        margin-top: 40px;
        }

        .methods__desc{
        h2{
        color: #333;
        font-family: Noto Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; /* 56px */
        @media only screen and (max-width: 992px){
        font-size: 24px;
        }

        @media only screen and (max-width: 768px){
        font-size: 18px;
        }

        @media only screen and (max-width: 576px){
        font-size: 16px;
        }
        }
        }

        .methods__content{
        margin-top: 40px;
        @media only screen and (max-width: 992px){
        gap: 8px;   
        }
        &:last-child{
        margin-bottom: 0;
        }
        }

        .methods__content__row__item{
        margin-bottom: 0;
        -ms-flex: 0 0 32.2%;
        -webkit-flex: 0 0 32.2%;
        flex: 0 0 32.2%;
        max-width: 32.2%;
        margin-bottom: 20px;
        @media only screen and (max-width: 992px){
        margin-bottom: 8px;
        }
        @media only screen and (max-width: 767px){
        -ms-flex: 0 0 48.5%;
        -webkit-flex: 0 0 48.5%;
        flex: 0 0 48.5%;
        max-width: 48.5%;
        margin-bottom: 0;
        }
        }
        `;

        export default LDMethodsStyle;

import React from 'react';
import CoreValueStyle from './CoreValueStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import excellence from '../../../assets/Images/excellence.svg';
import Integrity from '../../../assets/Images/Integrity.svg';
import Knowledge from '../../../assets/Images/Knowledge.svg';
import Professionalism from '../../../assets/Images/Professionalism.svg';

const CoreValue = () => {

  return (
    <CoreValueStyle className={`container core`}>
      <div className={`container-full center core__desc`}>
        <HeaderText value='Our Core Value' />
      </div>
      <div className={`container-full core__content`}>
        <div className={`div-md-3 core__content__item`}>
          <div className='container-full center'>
            <Img image={excellence} />
            <Text value='Excellence' />
          </div>
        </div>
        <div className={`div-md-3 core__content__item`}>
          <div className='container-full center'>
            <Img image={Professionalism} />
            <Text value='Professionalism' />
          </div>
        </div>
        <div className={`div-md-3 core__content__item`}>
          <div className='container-full center'>
            <Img image={Integrity} />
            <Text value='Integrity' />
          </div>
        </div>
        <div className={`div-md-3 core__content__item`}>
          <div className='container-full center'>
            <Img image={Knowledge} />
            <Text value='Knowledge' />
          </div>
        </div>
      </div>
    </CoreValueStyle>
  );
}

export default CoreValue;
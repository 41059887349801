import React from 'react';
import StrategyCarouselStyle from './StrategyCarouselStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import carousel4 from '../../../assets/Images/carousel4.svg';
import carousel5 from '../../../assets/Images/carousel5.svg';
import carousel6 from '../../../assets/Images/carousel6.svg';

const StrategyCarousel = () => {

  return (
    <StrategyCarouselStyle className={`container strategy-carousel`}>
        <Carousel
          showArrows={false}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={2100}
        >
          <div className={`container-full strategy-carousel__box`}>
            <Img image={carousel4} className="img" />
          </div>
          <div className={`container-full strategy-carousel__box`}>
            <Img image={carousel5} className="img" />
          </div>
          <div className={`container-full strategy-carousel__box`}>
            <Img image={carousel6} className="img" />
          </div>
        </Carousel>
    </StrategyCarouselStyle>
  );
}

export default StrategyCarousel;
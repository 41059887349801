import React from 'react';
import WhatWeOfferStyle from './WhatWeOfferStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import { NavLink } from 'react-router-dom';




const WhatWeOffer = () => {

  return (
    <WhatWeOfferStyle className={`container-full`}>
      <div className={`container offer`}>
        <div className={`container-full center offer__desc`}>
          <HeaderText value="What we offer" />
        </div>
        <div className={`container-full offer__offers`}>
          <div className={`container-full offer__offers__row`}>
            <NavLink to="/strategy" className={`offer__offers__row__item --strategy`}></NavLink>
            <NavLink to="/learning-and-development" className={`offer__offers__row__item --ld`}></NavLink>
          </div>
          <div className={`container-full offer__offers__row`}>
            <NavLink to="/operations" className={`offer__offers__row__item --operations`}></NavLink>
            <NavLink to="/technology" className={`offer__offers__row__item --technology`}></NavLink>
            <NavLink to="/people" className={`offer__offers__row__item --people`}></NavLink>
          </div>
        </div>
      </div>
    </WhatWeOfferStyle>
  );
}

export default WhatWeOffer;
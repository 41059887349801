    import styled from 'styled-components';

    const CoreValueStyle = styled.section`
    box-sizing: border-box;
    padding: 40px 0 92px 0;
    width: 100%;

    .core__desc{
    h2{
    color: #333;
    text-align: center;
    font-family: Noto Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; 
    letter-spacing: -1.6px;
    @media only screen and (max-width: 992px){
    font-size: 26px;
    }

    @media only screen and (max-width: 768px){
    font-size: 20px;
    }

    @media only screen and (max-width: 576px){
    font-size: 16px;
    }
    }
    }
    .core__content{
    margin-top: 72px;
    @media only screen and (max-width: 992px){
    margin-top: 62px;
    }

    @media only screen and (max-width: 768px){
    margin-top: 45px;
    }

    @media only screen and (max-width: 576px){
    margin-top: 30px;
    }
    }
    .core__content__item{
    margin-bottom: 0;

    p{
    color: #333;
    text-align: center;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-top: 24px;
    width: 100%;

    @media only screen and (max-width: 992px){
    font-size: 14px;
    }

    @media only screen and (max-width: 768px){
    font-size: 13px;
    margin-top: 12px;
    margin-bottom: 26px;
    }

    @media only screen and (max-width: 576px){
    font-size: 12px;
    margin-top: 14px;
    margin-bottom: 24px;
    }
    }
    img{
    width: 80px;
    height: 80px;
    @media only screen and (max-width: 992px){
    width: 60px;
    height: 60px;
    }

    @media only screen and (max-width: 768px){
    width: 50px;
    height: 50px;
    }

    @media only screen and (max-width: 576px){
    width: 40px;
    height: 40px;
    }
    }
    }

    `;

    export default CoreValueStyle;

        import styled from 'styled-components';
        import strategy from '../../../assets/Images/Strategy.svg';
        import strategyMobile from '../../../assets/Images/strategy-mobile.svg';
        import strategyVariant from '../../../assets/Images/strategy-variant.svg';
        import people from '../../../assets/Images/people.svg';
        import peopleMobile from '../../../assets/Images/people-mobile.svg';
        import peopleVariant from '../../../assets/Images/people-variant.svg';
        import LD from '../../../assets/Images/L&D.svg';
        import LDMobile from '../../../assets/Images/L&D-mobile.svg';
        import LDVariant from '../../../assets/Images/LD-variant.svg';
        import operations from '../../../assets/Images/Operations.svg';
        import operationsMobile from '../../../assets/Images/operations-mobile.svg';
        import operationsVariant from '../../../assets/Images/operations-variant.svg';
        import technology from '../../../assets/Images/Technology.svg';
        import technologyMobile from '../../../assets/Images/technology-mobile.svg';
        import technologyVariant from '../../../assets/Images/technology-variant.svg';

        const WhatWeOfferStyle = styled.section`
        background: white;
        box-sizing: border-box;
        padding: 80px 0 24px 0;
        width: 100%;

        @media only screen and (max-width: 992px){
        padding-top: 60px;
        }
        @media only screen and (max-width: 768px){
        padding-top: 30px;
        }
        .offer__desc{
        h2{
        color: #828282;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        @media only screen and (max-width: 768px){
        color: #828282;
        font-family: Noto Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.8px;
        }
        }
        }
        .offer__offers{
        margin-top: 60px;
        @media only screen and (max-width: 992px){
        margin-top: 30px;
        }
        @media only screen and (max-width: 768px){
        margin-top: 16px;
        }
        }

        .offer__offers__row__item{
        height: 350px;
        }
        .--strategy{
        background: url(${strategy});
        background-size: cover;
        border-radius: 16px 0px 0px 0px;
        -ms-flex: 0 0 50%;
        -webkit-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        transition: all 0.5s linear;
        &:hover{
        background: url(${strategyVariant});
        background-size: cover;
        }
        @media only screen and (max-width: 1200px){
        background-size: 100%;
        background-repeat: no-repeat;
        height: 250px;
        &:hover{
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 991px){
        background-size: 100%;
        background-repeat: no-repeat;
        height: 187px;
        &:hover{
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 768px){
        -ms-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        background: url(${strategyMobile});
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right;
        border-radius: 0;
        height: 200px;
        &:hover{
        background: url(${strategyMobile});
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 767px){
        height: 170px;
        }
        @media only screen and (max-width: 576px){
        height: 120px;
        }
        }
        .--ld{
        background: url(${LD});
        background-size: cover;
        border-radius: 0px 16px 0px 0px;
        -ms-flex: 0 0 50%;
        -webkit-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        transition: all 0.5s linear;
        &:hover{
        background: url(${LDVariant});
        background-size: cover;
        }
        @media only screen and (max-width: 1200px){
        background-size: 100%;
        background-repeat: no-repeat;
        height: 250px;
        &:hover{
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 991px){
        background-size: 100%;
        background-repeat: no-repeat;
        height: 187px;
        &:hover{
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 768px){
        -ms-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        background: url(${LDMobile});
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right;
        border-radius: 0;
        height: 200px;
        &:hover{
        background: url(${LDMobile});
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 767px){
        height: 170px;
        }
        @media only screen and (max-width: 576px){
        height: 120px;
        }
        }
        .--operations{
        background: url(${operations});
        background-size: cover;
        border-radius: 0px 0px 0px 16px;
        -ms-flex: 0 0 33.3333%;
        -webkit-flex: 0 0 33.3333%;
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        transition: all 0.5s linear;
        &:hover{
        background: url(${operationsVariant});
        background-size: cover;
        }
        @media only screen and (max-width: 1200px){
        background-size: 100%;
        background-repeat: no-repeat;
        height: 250px;
        &:hover{
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 991px){
        background-size: 100%;
        background-repeat: no-repeat;
        height: 187px;
        &:hover{
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 768px){
        -ms-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        background: url(${operationsMobile});
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right;
        border-radius: 0;
        height: 200px;
        &:hover{
        background: url(${operationsMobile});
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 767px){
        height: 170px;
        }
        @media only screen and (max-width: 576px){
        height: 120px;
        }
        }
        .--technology{
        background: url(${technology});
        background-size: cover;
        -ms-flex: 0 0 33.3333%;
        -webkit-flex: 0 0 33.3333%;
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        transition: all 0.5s linear;
        &:hover{
        background: url(${technologyVariant});
        background-size: cover;
        }
        @media only screen and (max-width: 1200px){
        background-size: 100%;
        background-repeat: no-repeat;
        height: 250px;
        &:hover{
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 991px){
        background-size: 100%;
        background-repeat: no-repeat;
        height: 187px;
        &:hover{
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 768px){
        -ms-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        background: url(${technologyMobile});
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right;
        border-radius: 0;
        height: 200px;
        &:hover{
        background: url(${technologyMobile});
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 767px){
        height: 170px;
        }
        @media only screen and (max-width: 576px){
        height: 120px;
        }
        }
        .--people{
        background: url(${people});
        background-size: cover;
        border-radius: 0px 0px 16px 0px;
        -ms-flex: 0 0 33.3333%;
        -webkit-flex: 0 0 33.3333%;
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        transition: all 0.5s linear;
        &:hover{
        background: url(${peopleVariant});
        background-size: cover;
        }
        @media only screen and (max-width: 1200px){
        background-size: 100%;
        background-repeat: no-repeat;
        height: 250px;
        &:hover{
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 991px){
        background-size: 100%;
        background-repeat: no-repeat;
        height: 187px;
        &:hover{
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 768px){
        -ms-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        background: url(${peopleMobile});
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right;
        border-radius: 0;
        height: 200px;
        &:hover{
        background: url(${peopleMobile});
        background-size: 100%;
        }
        }
        @media only screen and (max-width: 767px){
        height: 170px;
        }
        @media only screen and (max-width: 576px){
        height: 120px;
        }
        }


        `;

        export default WhatWeOfferStyle;

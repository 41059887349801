        import styled from 'styled-components';

        const CoreValueStyle = styled.section`
        margin-top: 32px;
        width: 100%;

        .technology__desc{
        p{
        color: #4F4F4F;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        }
        @media only screen and (max-width: 768px){
        font-size: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        }
        .technology__focus{
        margin-top: 72px;
        }
        .technology__focus__desc{
        h2{
        color: #4F4F4F;
        font-family: Noto Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 175%; /* 56px */
        @media only screen and (max-width: 992px){
        font-size: 24px;
        }

        @media only screen and (max-width: 768px){
        font-size: 18px;
        }

        @media only screen and (max-width: 576px){
        font-size: 16px;
        }
        }
        }
        .technology__focus__content{
        margin-top: 43px;
        @media only screen and (max-width: 576px){
        margin-top: 16px;
        }
        }
        .technology__focus__content__item{
        -ms-flex: 0 0 32.2%;
        -webkit-flex: 0 0 32.2%;
        flex: 0 0 32.2%;
        max-width: 32.2%;
        margin-bottom: 0;
        @media only screen and (max-width: 576px){
        -ms-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 12px;
        }
        }
        .technology__list{
        margin-bottom: 42px;
        margin-top: 71px;
        @media only screen and (max-width: 576px){
        margin-bottom: 24px;
        margin-top: 24px;
        }
        p{
        color: #4F4F4F;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 175%;
        width: 100%;
        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        }
        @media only screen and (max-width: 768px){
        font-size: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        ul{
        margin-top: 10px;
        }
        li{
        color: #4F4F4F;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 175%;
        list-style: none;
        margin-bottom: 8px;
        &:last-child{
        margin-bottom: 0;
        }
        @media only screen and (max-width: 992px){
        font-size: 15%.5;
        }

        @media only screen and (max-width: 768px){
        font-size: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        }
        .--second{
        margin-top: 0;
        margin-bottom: 0;
        }
        .technology__roadmap{
        margin-top: 42px;
        }
        .technology__roadmap__desc{
        h2{
        color: #4F4F4F;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 175%; /* 28px */
        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        }
        @media only screen and (max-width: 768px){
        font-size: 14px;
        }
        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        }
        .technology__roadmap__explanation{
        margin-top: 48px;
        }
        .technology__roadmap__explanation__left{
        margin-bottom: 0;
        h2{
        color: #333;
        font-family: Noto Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 38.4px */
        @media only screen and (max-width: 992px){
        font-size: 24px;
        }

        @media only screen and (max-width: 768px){
        font-size: 18px;
        }

        @media only screen and (max-width: 576px){
        font-size: 16px;
        margin-bottom: 16px;
        }
        }
        }
        .technology__roadmap__explanation__right{
        margin-bottom: 0;
        p{
        color: #333;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; /* 28px */
        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        }
        @media only screen and (max-width: 768px){
        font-size: 14px;
        }
        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        }
        .technology__roadmap__content{
        margin-top: 40px;
        }
        .--second{
        margin-top: 42px;
        @media only screen and (max-width: 576px){
        margin-top: 0px;
        }

        }
        .technology__roadmap__content__desc{
        p{
        color: #4F4F4F;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; /* 28px */
        span{
        font-family: Noto Sans;
        font-weight: 600;
        }

        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        }
        @media only screen and (max-width: 768px){
        font-size: 14px;
        }
        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        }
        .technology__roadmap__content__group{
        margin-top: 40px;
        }
        .technology__roadmap__content__group__row{
        margin-bottom: 24px;
        @media only screen and (max-width: 768px){
        margin-bottom: 0px;
        }
        /* @media only screen and (max-width: 576px){
        margin-bottom: 0px;
        } */
        &:last-child{
        margin-bottom: 0;
        }
        }
        .technology__roadmap__content__group__row__item{
        margin-bottom: 0;
        img{
        width: 80px;
        height: 80px;

        @media only screen and (max-width: 992px){
        width: 60px;
        height: 60px;
        }

        @media only screen and (max-width: 768px){
        width: 50px;
        height: 50px;
        }

        @media only screen and (max-width: 576px){
        width: 40px;
        height: 40px;
        }
        }
        p{
        color: #333;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; /* 28px */
        text-align: center;
        width: 100%;
        margin-top: 29px;
        margin-bottom: 0;
        @media only screen and (max-width: 992px){
        font-size: 14px;
        }
        @media only screen and (max-width: 768px){
        font-size: 13px;
        margin-top: 14px;
        margin-bottom: 24px;
        }
        @media only screen and (max-width: 576px){
        font-size: 12px;
        margin-top: 14px;
        margin-bottom: 24px;
        }
        }
        }


        `;

        export default CoreValueStyle;
import React, { useState } from 'react';
import NavbarStyle from './NavbarStyles';
import Img from '../../atoms/Image/Img';
import logo from '../../../assets/Images/logo.svg';
import search from '../../../assets/Images/search.svg';
import { Link, NavLink } from 'react-router-dom';
import MobileNavbar from '../MobileNavBar/MobileNavbar';
import cross from '../../../assets/Images/Cross.svg';
import menu from '../../../assets/Images/Menu.svg';



const Navbar = () => {
    const [mobileHeaderDisplay, setMobileHeaderDisplay] = useState(false);

    return (
        <NavbarStyle className={`container`}>
            <div className='logo'>
                <Link to="/">
                    <Img image={logo} alt="logo" />
                </Link> 
            </div>
            <div className='navlinks'>
                <NavLink to="/about" className={({ isActive }) => isActive ? "active" : 'navlinks__item'} >About Us</NavLink>
                <NavLink to="/strategy" className={({ isActive }) => isActive ? "active" : 'navlinks__item'}>Strategy</NavLink>
                <NavLink to="/operations" className={({ isActive }) => isActive ? "active" : 'navlinks__item'}>Operations</NavLink>
                <NavLink to="/technology" className={({ isActive }) => isActive ? "active" : 'navlinks__item'}>Technology</NavLink>
                <NavLink to="/learning-and-development" className={({ isActive }) => isActive ? "active" : 'navlinks__item'}>Learning &amp; Development</NavLink>
                <NavLink to="/services" className={({ isActive }) => isActive ? "active" : 'navlinks__item'}>People Services</NavLink>
                <NavLink to="/contact" className={({ isActive }) => isActive ? "active" : 'navlinks__item'}>Contact Us</NavLink>
                <Img image={search} alt="search icon" />
            </div>
            <div className='hamburger' onClick={() => setMobileHeaderDisplay(!mobileHeaderDisplay)}>
                {
                    mobileHeaderDisplay ? 
                    <Img image={cross} /> :
                    <Img image={menu} />
                }
               
            </div>
            {
                mobileHeaderDisplay && <MobileNavbar />
            }
        </NavbarStyle>
    );
}

export default Navbar;
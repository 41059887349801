import React from 'react';
import FooterStyle from './FooterStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import logo from '../../../assets/Images/logo-grey.svg';
import { Link } from 'react-router-dom';
import facebook from '../../../assets/Images/Facebook.svg';
import twitter from '../../../assets/Images/Twitter.svg';
import instagram from '../../../assets/Images/Instagram.svg';
import linkedin from '../../../assets/Images/Linkedin.svg';

const Footer = () => {

  return (
    <FooterStyle className={`container-full footer`}>
      <div className={`container center footer__img`}>
        <Img image={logo} />
      </div>
      <div className={`container center footer__nav`}>
        <Link to={`/about`}> About Us </Link>
        <Link to={`/contact`}> Contact Us </Link>
        <Link to={`/Technology`}> Technology </Link>
        <Link to={`/Trainings`}> Trainings </Link>
        <Link to={`/People-Services`}> People Services </Link>
      </div>
      <div className={`container center footer__socials`}>
        <Img image={facebook} />
        <Img image={instagram} />
        <Img image={twitter} />
        <Img image={linkedin} />
      </div>
      <div className={`container footer__bottom`}>
        <div className={`footer__bottom__left`}>
          <Text value='2024 Globedge. All right reserved.' />
        </div>
        <div className={`footer__bottom__right`}>
          <a href='#'>Privacy Policy</a>
          <a href='#'>Terms of Service</a>
          <a href='#'>Cookies Settings</a>
        </div>
      </div>
    </FooterStyle>
  );
}

export default Footer;
import React from 'react';
import LDDeployStyle from './LDDeployStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import education from '../../../assets/Images/Education.svg';
import audio from '../../../assets/Images/Audio Education.svg';
import work from '../../../assets/Images/Work in team.svg';

const LDDeploy = () => {

  return (
    <LDDeployStyle className={`container deploy`}>
      <div className={`container-full deploy__desc`}>
        <HeaderText value='How do we deploy these trainings?' />
        <Text value='At Globedge Capital, we have three major learning styles:' />
      </div>
      <div className={`container-full deploy__content`}>
        <div className={`div-md-3 deploy__content__item`}>
          <div className={`container-full center`}>
            <Img image={education} />
            <p>Visual learning <br/> <span>(learning by seeing or watching)</span></p>
          </div>
        </div>
        <div className={`div-md-3 deploy__content__item`}>
          <div className={`container-full center`}>
            <Img image={audio} />
            <p>Auditory learning <br/> <span>(learning by listening to and absorbing information)</span></p>
          </div>
        </div>
        <div className={`div-md-3 deploy__content__item`}>
          <div className={`container-full center`}>
            <Img image={work} />
            <p> Kinesthetic <br/> <span>(learning through action, or by doing)</span></p>
          </div>
        </div>
      </div>
    </LDDeployStyle>
  );
}

export default LDDeploy;
        import styled from 'styled-components';

        const LDGoalsStyle = styled.section`
        margin-top: 104px;
        width: 100%;
        @media only screen and (max-width: 576px){
        margin-top: 50px;
        }

        .goals__desc{
        h2{
        color: #333;
        font-family: Noto Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 175%;
        margin-bottom: 40px;
        @media only screen and (max-width: 992px){
        font-size: 24px;
        }

        @media only screen and (max-width: 768px){
        font-size: 18px;
        }

        @media only screen and (max-width: 576px){
        font-size: 16px;
        margin-bottom: 20px;
        }
        }
        p{
        color: #333;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        span{
        font-family: Noto Sans;
        font-weight: 500;
        }
        &:last-child{
        margin-top: 24px;
        }
        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        }
        @media only screen and (max-width: 768px){
        font-size: 14px;
        }
        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        }

        .goals__content{
        margin-top: 40px;
        }

        .goals__content__item{
        margin-bottom: 0;
        }

        .goals__content__item__text{
        margin-bottom: 8px;
        position: relative;
        p{
        color: #4F4F4F;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; /* 28px */
        margin-left: 18px;  
        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        }

        @media only screen and (max-width: 768px){
        font-size: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        span{
        color: #4F4F4F;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Noto Sans;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        left: 0;
        line-height: 0;
        margin-top: 4.5px;
        position: absolute;
        top: 0;
        }
        }

        .goals__provide{
        margin-top: 113px;
        @media only screen and (max-width: 576px){
        margin-top: 50px;
        }
        }

        .goals__provide__img{
        margin-bottom: 0;
        }

        .goals__provide__text{
        margin-bottom: 0;
        margin-top: 26.5px;
        h2{
        color: #333;
        font-family: Noto Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        @media only screen and (max-width: 992px){
        font-size: 22px;
        }

        @media only screen and (max-width: 767px){
        font-size: 18px;
        }

        @media only screen and (max-width: 576px){
        font-size: 16px;
        }
        }
        ul{
        margin-top: 24px;
        }
        li{
        color: #333;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; /* 28px */
        list-style: none;
        }
        }

        .goals__provide__text__list{
        margin-top: 24px;
        }

        .goals__provide__text__list__item{
        margin-bottom: 8px;
        position: relative;
        p{
        color: #333;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; /* 28px */
        margin-left: 18px;  
        @media only screen and (max-width: 992px){
        font-size: 15.5px;
        }

        @media only screen and (max-width: 768px){
        font-size: 14px;
        }

        @media only screen and (max-width: 576px){
        font-size: 12px;
        }
        }
        span{
        color: #333;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Noto Sans;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        left: 0;
        line-height: 0;
        margin-top: 4.5px;
        position: absolute;
        top: 0;

        }
        }

        `;

        export default LDGoalsStyle;

import React from 'react';
import LDExperiencingStyle from './LDExperiencingStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import sad from '../../../assets/Images/sad.svg';

const LDExperiencing = () => {

  return (
    <LDExperiencingStyle className={`container experience`}>
      <div className={`container-full experience__desc`}>
        <Text value='The SHRM and Clear Company statistics reveal that employees see the importance of training and are more inclined to stay longer in organizations that prioritize training and development. The Zig Ziglar quote speaks to the need for employee training irrespective of identified risk, and that it’s riskier to keep uninspired, untrained and unsatisfied workforce in organization due to the concern because the benefits outweigh the risks. Benefits of training versus the Consequences of not training below:' />
      </div>
      <div className={`container-full experience__content`}>
        <div className={`div-md-6 experience__content__img`}>
          <Img image={sad} className="img" />
        </div>
        <div className={`div-md-6 experience__content__text`}>
          <Text value='Are you experiencing?' />
          <ul>
            <li>Decreased productivity</li>
            <li>High staff turnover rates</li>
            <li>Negative impact of morale</li>
            <li>Increase in staff absence</li>
            <li>Poor job performance</li>
            <li>Increased level of work-related stress</li>
            <li>Unhappy and unsatisfied employees</li>
          </ul>
        </div>
      </div>
    </LDExperiencingStyle>
  );
}

export default LDExperiencing;
import React, { useState } from 'react';
import ContactFormStyle from './ContactFormStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import Input from '../../atoms/Input/Input';
import caret from '../../../assets/Images/caret-down.svg';
import Button from '../../atoms/Button/Button';
import cogoToast from "cogo-toast";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    category: "",
    message: ""
  })

  const inputChange = async (name: string, value: string) => {
    const fieldsValues: any = Object.assign({}, fields);
    fieldsValues[name] = value;
    await setFields(fieldsValues);
  };

  const onFormSubmit = () => {
    return (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      let message = `
        company name: ${fields.companyName} \n
        category: ${fields.category} \n
        message: ${fields.message}
      `;
      
      const serviceID = "service_2wsr4xr";
      const templateID = "template_rkwdfs1";
      const publicKey = "lbsLgFzfry6BtbuZQ";

      const templateParams = {
        from_name: fields.name,
        email: fields.email,
        phone: fields.phone,
        to_name: "Globedge Capital",
        message: message
      }

      emailjs.send(serviceID, templateID, templateParams, publicKey)
        .then((response) => {
          setLoading(false);
          cogoToast.success("Email sent successfully!", { position: "bottom-right" });
          setFields({
            name: "",
            email: "",
            phone: "",
            companyName: "",
            category: "",
            message: ""
          })
        })
        .catch((error) => {
          setLoading(false);
          cogoToast.error("Couldn't send email, try again!", { position: "bottom-right" });
        })

    }
  }

  return (
    <ContactFormStyle className={`form`}>
      <form onSubmit={onFormSubmit()}>
        <div className={`container-full form__row`}>
          <div className={`div-sm-12 form__row__item`}>
            <Text value='Name' />
            <Input type="text" className='input' placeholder='E.g. Damilola John' value={fields.name} name="name" onChange={(event:any) => inputChange(event.target.name, event.target.value)} required />
          </div>
        </div>
        <div className={`container-full form__row`}>
          <div className={`div-md-6 form__row__item`}>
            <Text value='Email Address' />
            <Input type="email" className='input' placeholder='E.g. Dami@gmail.com' value={fields.email} name="email" onChange={(event:any) => inputChange(event.target.name, event.target.value)} required />
          </div>
          <div className={`div-md-6 form__row__item`}>
            <Text value='Phone number' />
            <Input type="text" className='input' placeholder='E.g. +2349043537' value={fields.phone} name="phone" onChange={(event:any) => inputChange(event.target.name, event.target.value)} required/>
          </div>
        </div>
        <div className={`container-full form__row`}>
          <div className={`div-md-6 form__row__item`}>
            <Text value='Company Name' />
            <Input type="text" className='input' placeholder='E.g. Globedge' value={fields.companyName} name="companyName" onChange={(event:any) => inputChange(event.target.name, event.target.value)} />
          </div>
          <div className={`div-md-6 form__row__item`}>
            <Text value='Category' />
            <div className='input'>
              <select value={fields.category} name="category" onChange={(event:any) => inputChange(event.target.name, event.target.value)}>
                <option value={""}>Select Category</option>
                <option value={"inquiry"}>Inquiry</option>
                <option value={"complaint"}>Complaint</option>
              </select>
              <Img image={caret} />
            </div>
          </div>
        </div>
        <div className={`container-full form__row`}>
          <div className={`div-sm-12 form__row__item`}>
            <Text value='Message' />
            <textarea className='input' placeholder='Type your message' value={fields.message} name="message" onChange={(event:any) => inputChange(event.target.name, event.target.value)} required></textarea>
          </div>
        </div>
        <div className={`container-full form__action`}>
          <Button value={`Send Message`} className="button" isLoading={loading} />
        </div>
      </form>
    </ContactFormStyle>
  );
}

export default ContactForm;
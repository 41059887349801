import React from 'react';
import LDGoalsStyle from './LDGoalsStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
import standing from '../../../assets/Images/standing.svg';

const LDGoals = () => {

  return (
    <LDGoalsStyle className={`container goals`}>
      <div className={`container-full goals__desc`}>
        <HeaderText value='Training Goals, Need Analysis, Assessment and Programs' />
        <p>To train and update your workforce, conduct a Training Need Analysis and Assessment to identify skill gaps and set SMART goals. At Globedge Capital, we evaluate the program's performance and use real-time case studies for practical solutions.</p>
        <p>We have series of corporate training programs that will transform your business to achieve lots more than the aforementioned training benefits. The categories -
          <span>Business skills, Soft skills, Technology skills, Industry certification, and Leadership skills trainings,</span> which are further enlisted but not limited to the topics/courses below:</p>
      </div>
      <div className={`container-full goals__content`}>
        <div className={`div-md-4 goals__content__item`}>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Excellent Leadership</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Effective Team Communication</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Effective Project Management</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Product Management</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p>Operations Management </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Design Thinking</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Business Intelligence, Big Data and Data Analytics</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Lean Six Sigma</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Negotiation and Conflict Management</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Managing Diversity &amp; Building A Strong Team</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Time Management</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Leading Globally</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Building High Performance Team</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Executive Leadership</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Management Information System</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Today Work Culture Training</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Fundamentals of Sales and Marketing</p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p>Professional Scrum Master Training </p>
          </div>

        </div>

        <div className={`div-md-4 goals__content__item`}>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Business Process Management </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Leading with Emotional Intelligence </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Organizational Culture, Diversity and Ethics </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Enterprise and Solution Architecture </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Innovation and Sustainability </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Internal Control &amp; Compliance </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Management Strategies for People and Resources </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Leadership For Product Managers </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Human Resources Management </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Leadership &amp; Management Masterclass </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Operational Excellence </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Managing Virtual Teams </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Strategic Branding </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Stress Management (Wellness, Hygiene and Nutrition) </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Building a Culture of Respect </p>
          </div>
        </div>

        <div className={`div-md-4 goals__content__item`}>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Excellent Customer Service Delivery and Client Relations Training </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Critical Thinking and Problem-Solving Training </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Organizational Productivity Training </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Corporate Communication </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Digital Transformation </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Cloud Infrastructure Training </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Career Growth: Self-Assessment </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Organization Structure, Change, and the Future of Management </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Supply Chain Management </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Risk Management </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Workplace Safety, Housekeeping and Ergonomics </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Performance Management </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Quality Assurance (Q/A) Training </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Elements of Ethical Leadership </p>
          </div>
          <div className={`goals__content__item__text`}>
            <span>.</span>
            <p> Public Speaking for Sales </p>
          </div>

        </div>
      </div>
      <div className={`container-full goals__provide`}>
        <div className={`div-md-6 goals__provide__img`}>
          <Img image={standing} className="img" />
        </div>
        <div className={`div-md-6 goals__provide__text`}>
          <HeaderText value='Our training provide answers to:' />
          <div className={`goals__provide__text__list`}>
            <div className={`goals__provide__text__list__item`}>
              <span>.</span>
              <p> What level of training – Organizational Level, Operational    Level, or Individual Level? </p>
            </div>
            <div className={`goals__provide__text__list__item`}>
              <span>.</span>
              <p> What is the purpose of the training? </p>
            </div>
            <div className={`goals__provide__text__list__item`}>
              <span>.</span>
              <p> What specific skills do employees need to learn or refresh? </p>
            </div>
            <div className={`goals__provide__text__list__item`}>
              <span>.</span>
              <p>  Are there new or recently changed procedures that need additional instruction? </p>
            </div>
            <div className={`goals__provide__text__list__item`}>
              <span>.</span>
              <p> What outcomes are expected from the training? </p>
            </div>
            <div className={`goals__provide__text__list__item`}>
              <span>.</span>
              <p> Who will require the training? Individuals or an entire department? </p>
            </div>
            <div className={`goals__provide__text__list__item`}>
              <span>.</span>
              <p> What knowledge, skills and abilities (KSAs) will employees gain from the training? </p>
            </div>
            <div className={`goals__provide__text__list__item`}>
              <span>.</span>
              <p> How will training add value? </p>
            </div>
          </div>
        </div>
      </div>
    </LDGoalsStyle>
  );
}

export default LDGoals;
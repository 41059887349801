    import styled from 'styled-components';

    const AboutTemplateStyle = styled.div`
    .nav{
    background: white;
    position: fixed;
    z-index: 5000;
    }
    .ld-hero{
    margin-top: 129px;
    @media only screen and (max-width: 576px){
    &{
    margin-top: 110px;
    }
    }
    }
    .testimonials{
    background: white;
    box-sizing: border-box;
    padding: 53px 0 10px 0;
    }
    .contact{
    background: white;
    box-sizing: border-box;
    padding: 115px 0 41px 0;
    }
    .ld-hero__desc{
    h2{
    color: #828282;
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.47px;
    margin-bottom: 12px;
    text-align: center;
    width: 100%;
    @media only screen and (max-width: 768px){
        font-size: 12px;
        }
    }
    p{
    color: #4F4F4F;
    font-family: Noto Sans;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%; /* 36px */
    text-align: center;
    width: 80%;
    @media only screen and (max-width: 992px){
        font-size: 22px;
        }

    @media only screen and (max-width: 768px){
        font-size: 18px;

    }

    @media only screen and (max-width: 576px){
        font-size: 14px;
      }    
    }
    }
    .ld__hint{
    margin-top: 24px;
    p{
    color: #4F4F4F;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%; /* 28px */
    @media only screen and (max-width: 992px){
    font-size: 15.5px;
    }

    @media only screen and (max-width: 768px){
    font-size: 14px;
    }

    @media only screen and (max-width: 576px){
    font-size: 12px;
    }
    }
    }
    .ld__quotes{
    margin-top: 36px;
    }
    .ld__quotes__item{
    margin-bottom: 26px;
    &:last-child{
    margin-bottom: 0;
    }
    p{
    color: #4F4F4F;
    font-family: Noto Sans;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%; /* 36px */
    text-align: center;
    width: 80%;
    @media only screen and (max-width: 992px){
    font-size: 22px;
    }

    @media only screen and (max-width: 768px){
    font-size: 18px;
    }

    @media only screen and (max-width: 576px){
    font-size: 14px;
    }
    }
    }
    `;

    export default AboutTemplateStyle;

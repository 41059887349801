import React, { useState, useEffect } from 'react';
import AboutHeroSectionStyle from './AboutHeroSectionStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Button from '../../atoms/Button/Button';
import Img from '../../atoms/Image/Img';
import moon from '../../../assets/Images/moon.svg';
import subtract from '../../../assets/Images/Subtract.svg';
import intersect from '../../../assets/Images/Intersect.svg';
import fullmoon from '../../../assets/Images/full-moon.svg';



const AboutHeroSection = () => {    

    return (
        <AboutHeroSectionStyle className={`container about`}>
           <div className={`container-full center about__desc`}>
              <HeaderText value="About Us" />
           </div>
           <div className={`container-full about__content`}>
              <div className={`div-md-5 about__content__left`}>
                <Text value='Our Story' />
                <HeaderText value='Who we are' />
              </div>
              <div className={`div-md-7 about__content__right`}>
                <Text value='“We are a team of consultants having robust and combined experience of over 20years in consulting (building corporate brands) and transforming businesses across Africa, starting from Nigeria.' />
                <Text value='Our focus remains identifying internal gaps within organizations and proffering leading solutions through practical innovations in business process improvement, strategy and operations remodeling, business technology, resource management, and workforce learning & development to keep organizations constantly transformed & informed of industry knowledge, standards and trends in the highly competitive local & global landscape and ever-evolving people-process-technology business world' />
                <Text value='With a reputation for excellence and executing actionable plans yielding transformative results, our primary focus is bringing efficiency to business operations, sustainable values to customers, and productivity & profitability to business shareholders.' />
              </div>
           </div>
        </AboutHeroSectionStyle>
    );
}

export default AboutHeroSection;
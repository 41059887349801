        import styled from 'styled-components';
        import contactBg from '../../../assets/Images/contact-bg.svg';

        const AboutHeroSectionStyle = styled.section`
        box-sizing: border-box;
        margin-top: 136px;
        width: 100%;
        @media only screen and (max-width: 576px){
        margin-top: 100px;
        }

        .contact__desc{
        h2{

        display: none;
        @media only screen and (max-width: 576px){
        color: #828282;
        display: block;
        font-family: Noto Sans;   
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 28px;
        }
        }
        }

        .contact-hero__box{
        background-image: url(${contactBg});
        border-radius: 24px;
        background-repeat: no-repeat;
        background-size: cover;
        height: 400px;


        @media only screen and (max-width: 576px){
        height: 160px;
        }

        h2{
        color: #FFF;
        font-family: Noto Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 38.4px */
        margin-bottom: 21px;
        text-align: center;
        width: 100%;
        @media only screen and (max-width: 992px){
        font-size: 24px;
        }

        @media only screen and (max-width: 768px){
        font-size: 22px;
        }

        @media only screen and (max-width: 576px){
        font-size: 20px;
        }
        }
        p{
        color: #FFF;
        text-align: center;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 24px */
        width: 35%;
        @media only screen and (max-width: 768px){
        font-size: 14px;
        width: 100%;
        }
        }
        }


        `;

        export default AboutHeroSectionStyle;

import React, { useEffect } from 'react';
import HomeTemplateStyles from './HomeTemplateStyles';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Image from '../../atoms/Image/Img';
import Navbar from '../../organisms/Navbar/Navbar';
import HeroSection from '../../organisms/HeroSection/HeroSection';
import WhatWeOffer from '../../organisms/WhatWeOffer/WhatWeOffer';
import WhoWeAre from '../../organisms/WhoWeAre/WhoWeAre';
import WhyChooseUs from '../../organisms/WhyChooseUs/WhyChooseUs';
import OurClients from '../../organisms/OurClients/OurClients';
import Testimonials from '../../organisms/Testimonials/Testimonials';
import Contact from '../../organisms/Contact/Contact';
import Footer from '../../organisms/Footer/Footer';
// import logo from '../../../assets/images/logo.svg';

const HomeTemplate = () => {

  return (
    <>
      <HomeTemplateStyles>
        <div className={`container-full`}>
          <div className={`container-full nav`}>
            <Navbar />
          </div>
          <HeroSection />
          <WhatWeOffer />
          <WhoWeAre />
          <WhyChooseUs />
          <OurClients />
          <section className={`container-full testimonials`}>
            <Testimonials />
          </section>
          <section className={`container-full contact`}>
            <Contact />
          </section>
          <Footer />
        </div>
      </HomeTemplateStyles>
    </>
  );
};

export default HomeTemplate;

import React, { useEffect, useState } from 'react';
import OurClientsStyle from './OurClientsStyle';
import Text from '../../atoms/Text/Text';
import HeaderText from '../../atoms/HeaderText/Text';
import Img from '../../atoms/Image/Img';
// import aiico from '../../../assets/Images/aiico.svg';
// import stanbic from '../../../assets/Images/stanbic.svg';
// import crown from '../../../assets/Images/crown.svg';
// import ekedc from '../../../assets/Images/ekedc.svg';
// import devcenter from '../../../assets/Images/devcenter.svg';
// import checkers from '../../../assets/Images/checkers.svg';
// import firs from '../../../assets/Images/firs.svg';
// import training from '../../../assets/Images/training.svg';
// import tenece from '../../../assets/Images/tenece.svg';
// import zenith from '../../../assets/Images/zenith.svg';
import engee from '../../../assets/Images/engee.png';
import ITF from '../../../assets/Images/ITF.jpeg';
import NITAD from '../../../assets/Images/NITAD.jpeg';
import Abbey from '../../../assets/Images/Abbey.jpeg';
import PSSDC from '../../../assets/Images/PSSDC.jpeg';
import CIPM from '../../../assets/Images/CIPM.jpeg';
import CBN from '../../../assets/Images/CBN.png';
import DMO from '../../../assets/Images/DMO.jpeg';
import BOI from '../../../assets/Images/BOI.png';
import saipem from '../../../assets/Images/saipem.png';
import bua from '../../../assets/Images/bua.png';
import NNPC from '../../../assets/Images/NNPC.png';
import total from '../../../assets/Images/total energies.png';
import CBG from '../../../assets/Images/CBG.png';
import OICL from '../../../assets/Images/OICL.jpeg';
import nirsal from '../../../assets/Images/nirsal.jpeg';
import PCL from '../../../assets/Images/PCL.jpeg';
import ilaji from '../../../assets/Images/Ilaji.jpeg';
import flexolutions from '../../../assets/Images/flexolutions.png';
import connect from '../../../assets/Images/connect.png';

const OurClients = () => {
  const [scroll, setScroll] = useState(true);
  let forthTimeout:any;
  let backTimeout:any;

  useEffect(() => {
    if(scroll === true){
      scrollForth();
    }
    else{
      scrollBack();
    }

    return () => {
      clearTimeout(forthTimeout);
      clearTimeout(backTimeout);
    }
  }, [scroll])

  const scrollForth = () => {
    forthTimeout = window.setTimeout(() => {
      for(let i = 0; i < document.getElementsByClassName("clients__logos").length; i++ ){
        document.getElementsByClassName("clients__logos")[i].scrollTo(5000, 0);
      }
      setScroll(!scroll);
    }, 3000)
    
  }

  const scrollBack = () => {
    backTimeout = window.setTimeout(() => {
      for(let i = 0; i < document.getElementsByClassName("clients__logos").length; i++ ){
        document.getElementsByClassName("clients__logos")[i].scrollTo(0, 5000);
      }
      setScroll(!scroll);
    }, 3000)
    
  }

  return (
    <OurClientsStyle className={`container-full clients`}>
      <div className={`container-full center clients__desc`}>
        <HeaderText value='Our Clients' />
      </div>
      <div className={`container-full clients__logos`}>
        <Img image={engee} alt="engee" />
        <Img image={NNPC} alt="NNPC" />
        <Img image={total} alt="total energies" />
        <Img image={CBG} alt="CBG" />
        <Img image={OICL} alt="OICL" />
        <Img image={nirsal} alt="nirsal" />
        <Img image={PCL} alt="PCL" />
        <Img image={ilaji} alt="ilaji" />
        <Img image={flexolutions} alt="flexolutions" />
        <Img image={connect} alt="connect" />
      </div>
      <div className={`container-full clients__logos`}>
        <Img image={ITF} alt="ITF" />
        <Img image={NITAD} alt="NITAD" />
        <Img image={Abbey} alt="Abbey" />
        <Img image={PSSDC} alt="PSSDC" />
        <Img image={CIPM} alt="CIPM" />
        <Img image={CBN} alt="CBN" />
        <Img image={DMO} alt="DMO" />
        <Img image={BOI} alt="BOI" />
        <Img image={saipem} alt="saipem" />
        <Img image={bua} alt="bua" />
      </div>
    </OurClientsStyle>
  );
}

export default OurClients;